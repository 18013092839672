function groupingItemOnSelect(props) {
  const { setSelectedItemIds, itemId } = props;

  setSelectedItemIds((selectedItemIds) => {
    if (selectedItemIds.some((id) => id === itemId)) {
      return selectedItemIds.filter((id) => id !== itemId);
    } else {
      const newIds = [...selectedItemIds, itemId];
      return newIds;
    }
  });
}

function groupingAllOnSelect(props) {
  const { isSelected, setSelectedItemIds, entities } = props;
  if (isSelected) {
    if (entities) {
      const allIds = entities.map((el) => el.id);
      setSelectedItemIds(allIds);
    }
  } else {
    setSelectedItemIds([]);
  }
}

// check official documentations: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Row%20Selection&selectedStory=Custom%20Selection%20Column%20Header%20Style&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
export function getSelectRow(props) {
  const { entities, setSelectedItemIds, disabledSelect } = props;
  return {
    mode: "checkbox",
    clickToSelect: false,
    hideSelectAll: false,
    hideSelectColumn: disabledSelect,
    onSelect: (row) =>
      groupingItemOnSelect({
        setSelectedItemIds,
        itemId: row.id,
      }),
    onSelectAll: (isSelected) =>
      groupingAllOnSelect({ isSelected, setSelectedItemIds, entities }),
  };
}
