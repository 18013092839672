import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useGetExaminationByIdQuery } from "../../../apis/ExaminationApi";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import JuniorParts from "../PraceticeTestParts/JuniorParts";
import JuniorPartsResult from "../PraceticeTestParts/JuniorPartsResult";

export default function JuniorLesson({ lesson, resultExam, setResultExam }) {
  const [activeTab, setActiveTab] = useState(1);
  useEffect(() => {
    setActiveTab(1);
  }, [lesson, setActiveTab]);

  const {
    isLoading: isGetLoading,
    isError: isGetError,
    isSuccess: isGetSuccess,
    data,
  } = useGetExaminationByIdQuery(lesson?.lessonExaminations[0]?.examinationId);

  if (!lesson) {
    return null;
  }

  return (
    <div>
      {lesson.lessonExaminations.length === 0 && (
        <Tabs activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
          <Tab className="lesson-tab" eventKey="1" title="Script">
            <div
              className="unformat-bootstrap-table"
              dangerouslySetInnerHTML={{
                __html: lesson?.content,
              }}
            ></div>
          </Tab>
          <Tab className="lesson-tab" eventKey="2" title="Vocab">
            <div
              className="unformat-bootstrap-table"
              dangerouslySetInnerHTML={{
                __html: lesson?.lessonAdditionalContents?.find(
                  (x) => x.name === "Vocabulary"
                )?.content,
              }}
            ></div>
          </Tab>
        </Tabs>
      )}
      {lesson.lessonExaminations.length > 0 && (
        <>
          {isGetLoading && <LoadingIndicator />}
          {isGetError && <div>Có lỗi xảy ra</div>}
          {isGetSuccess && resultExam ? (
            <JuniorPartsResult
              result={resultExam}
              onReturnBack={() => setResultExam(undefined)}
            />
          ) : (
            data?.data && (
              <JuniorParts
                exam={data.data}
                submitResult={(result) =>
                  setResultExam({
                    ...result,
                    examination: data.data,
                  })
                }
              />
            )
          )}
        </>
      )}
    </div>
  );
}
