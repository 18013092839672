import { useState, useEffect } from "react";
import { isFileContent } from "../utils/FileUtils";

export const useFileSource = (content, callback) => {
  const [fileSource, setFileSource] = useState(undefined);

  useEffect(() => {
    if (isFileContent(content)) {
      setFileSource(content);
    }
  }, [content]);

  const addFile = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      if (callback) {
        reader.onloadend = () => {
          callback(reader.result);
        };
      }
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return { fileSource, addFile };
};
