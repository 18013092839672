import React from "react";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../../utils/Table/TableSortingUtils";
import MasterLayout from "../../../components/MasterDetail/MasterLayout/MasterLayout";
import {
  useFindExaminationsMutation,
  deleteExamination,
  deleteExaminations,
} from "../../../../apis/ExaminationApi";
import { ExaminationType } from "../../../../utils/constants";

export default function HighSchoolTestsPage() {
  // Table columns
  const columns = [
    {
      dataField: "name",
      text: "Tên bài test",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "description",
      text: "Mô tả",
    },
  ];

  const examFilter = {
    examinationType: ExaminationType.HighSchool,
  };

  return (
    <MasterLayout
      title='Bài test thpt'
      columns={columns}
      useFindItemsMutation={useFindExaminationsMutation}
      deleteItem={deleteExamination}
      deleteItems={deleteExaminations}
      disabledRead={true}
      additionalDefaultFilter={examFilter}
    />
  );
}
