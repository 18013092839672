import { Form, Formik } from "formik";
import _ from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { QuestionType } from "../../../utils/constants";
import { compareAnswer } from "../../../utils/QuestionUtils";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import Input from "../Form/Input";

export default function ListeningParts({ exam, submitResult, isFinish }) {
  const [currentPassageIndex, setCurrentPassageIndex] = useState(0);
  const questionsRef = useRef([]);

  const initValue = useMemo(() => {
    const value = {
      examinationId: exam?.id,
      mark: 0,
      timeFinish: "",
      examinationQuestionResults: [],
    };
    for (const examCat of exam?.examinationCategories ?? []) {
      for (const group of examCat?.questionGroups ?? []) {
        for (const question of group?.questions ?? []) {
          if (question) {
            value.examinationQuestionResults.push({
              questionId: question.id,
              answer: "",
              isRightAnswer: false,
              mark: question.mark,
            });
            questionsRef.current.push(null);
          }
        }
      }
    }
    return value;
  }, [exam]);

  const scrollToQuestion = (passageIndex, questionNo) => {
    setCurrentPassageIndex(passageIndex);
    setTimeout(() => {
      questionsRef.current[questionNo - 1].scrollIntoView({
        behavior: "smooth",
      });
    }, 50);
  };

  const updateAnswer = (newValue, result, question, setFieldValue) => {
    const index = question.questionNo - 1;
    const questionResult = result.examinationQuestionResults[index];
    questionResult.answer = newValue;
    questionResult.isRightAnswer = question.answers.some((x) =>
      compareAnswer(newValue, x)
    );

    setFieldValue(`examinationQuestionResults[${index}]`, questionResult);
  };

  const renderTrackTabList = (tracks) => {
    return (
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        {tracks?.map((_, index) => (
          <li className="nav-item" role="presentation" key={index}>
            <button
              className={`nav-link ${
                index === currentPassageIndex ? "active" : ""
              }`}
              type="button"
              role="tab"
              onClick={() => setCurrentPassageIndex(index)}
            >
              Track {index + 1}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const renderTrackQuestionNavigation = (track, trackIndex, result) => {
    return track?.questionGroups.map((section) => {
      return section.questions.map((question, index) => (
        <span
          className={`list-item ${
            result.examinationQuestionResults[question.questionNo - 1]?.answer
              ? "list-item-right-answer"
              : ""
          }`}
          key={index}
          onClick={() => scrollToQuestion(trackIndex, question.questionNo)}
        >
          {question.questionNo}
        </span>
      ));
    });
  };

  const renderQuestionNavigationList = (tracks, result) => {
    return (
      <div className="test-question-list">
        {tracks?.map((item, index) => (
          <div className="test-question-list-part" key={index}>
            <h6 id={`part-${index + 1}`}>Track {index + 1}</h6>
            <div className="test-question-list-wrapper d-flex">
              {renderTrackQuestionNavigation(item, index, result)}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderTrackContent = (tracks, result, setFieldValue) => {
    return (
      <div className="tab-content" id="pills-tabContent">
        {tracks?.map((track, index) => (
          <div
            key={index}
            className={`tab-pane fade ${
              index === currentPassageIndex ? "show active" : ""
            }`}
          >
            <div className="question-wrapper">
              {track.content && (
                <audio className="audio-listening" controls>
                  <source src={track.content} />
                  <p>Your browser does not support HTML 5 audio element</p>
                </audio>
              )}
              <div
                className={`sections-wrapper border ${
                  tracks.length > 1 && "sections-wrapper-multiple-section"
                }`}
              >
                {track.questionGroups?.map((section, sectionIndex) => (
                  <div
                    className={
                      section.questionType === QuestionType.MutipleChoice.value
                        ? "section-multiple-wrapper"
                        : "section-wrapper border"
                    }
                    key={sectionIndex}
                  >
                    {section.questionType ===
                      QuestionType.MutipleChoice.value && (
                      <div className="listening-multiple-section">
                        <div className="section-header">
                          <h6>Section {sectionIndex + 1}</h6>
                        </div>
                        <div
                          className="unformat-bootstrap-table"
                          dangerouslySetInnerHTML={{
                            __html: section.description,
                          }}
                        />
                        <div className="section-content">
                          {section.questions?.map((question, questionIndex) => (
                            <div
                              className="question-container"
                              key={questionIndex}
                              id={question.questionNo}
                              ref={(el) =>
                                (questionsRef.current[question.questionNo - 1] =
                                  el)
                              }
                            >
                              <div className="question-content">
                                <strong className="question-number">
                                  {question.questionNo}
                                </strong>
                                <div
                                  className="question"
                                  dangerouslySetInnerHTML={{
                                    __html: question.content,
                                  }}
                                />
                              </div>
                              <div className="option">
                                {question.answerA && (
                                  <div className="form-check">
                                    <input
                                      type="radio"
                                      name={`examinationQuestionResults[${
                                        question.questionNo - 1
                                      }].answer`}
                                      value="A"
                                      onChange={() =>
                                        updateAnswer(
                                          "A",
                                          result,
                                          question,
                                          setFieldValue
                                        )
                                      }
                                    />
                                    <label htmlFor="A">
                                      A. {question.answerA}
                                    </label>
                                  </div>
                                )}
                                {question.answerB && (
                                  <div className="form-check">
                                    <input
                                      type="radio"
                                      name={`examinationQuestionResults[${
                                        question.questionNo - 1
                                      }].answer`}
                                      value="B"
                                      onChange={() =>
                                        updateAnswer(
                                          "B",
                                          result,
                                          question,
                                          setFieldValue
                                        )
                                      }
                                    />
                                    <label htmlFor="B">
                                      B. {question.answerB}
                                    </label>
                                  </div>
                                )}
                                {question.answerC && (
                                  <div className="form-check">
                                    <input
                                      type="radio"
                                      name={`examinationQuestionResults[${
                                        question.questionNo - 1
                                      }].answer`}
                                      value="C"
                                      onChange={() =>
                                        updateAnswer(
                                          "C",
                                          result,
                                          question,
                                          setFieldValue
                                        )
                                      }
                                    />
                                    <label htmlFor="C">
                                      C. {question.answerC}
                                    </label>
                                  </div>
                                )}
                                {question.answerD && (
                                  <div className="form-check">
                                    <input
                                      type="radio"
                                      name={`examinationQuestionResults[${
                                        question.questionNo - 1
                                      }].answer`}
                                      value="D"
                                      onChange={() =>
                                        updateAnswer(
                                          "D",
                                          result,
                                          question,
                                          setFieldValue
                                        )
                                      }
                                    />
                                    <label htmlFor="D">
                                      D. {question.answerD}
                                    </label>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {section.questionType === QuestionType.Text.value && (
                      <>
                        <div className="col-left">
                          <div className="section-header">
                            <h6>Section {sectionIndex + 1}</h6>
                          </div>
                          <div
                            className="unformat-bootstrap-table"
                            dangerouslySetInnerHTML={{
                              __html: section.description,
                            }}
                          ></div>
                        </div>
                        <div className="col-right d-flex row">
                          <div className="section-content">
                            {section.questions?.map(
                              (question, questionIndex) => (
                                <div
                                  className="question-container"
                                  key={questionIndex}
                                  id={question.questionNo}
                                  ref={(el) =>
                                    (questionsRef.current[
                                      question.questionNo - 1
                                    ] = el)
                                  }
                                >
                                  <div className="question-content">
                                    <strong className="question-number">
                                      {question.questionNo}
                                    </strong>
                                    <div
                                      className="question"
                                      dangerouslySetInnerHTML={{
                                        __html: question.content,
                                      }}
                                    />
                                  </div>
                                  <Input
                                    name={`examinationQuestionResults[${
                                      question.questionNo - 1
                                    }].answer`}
                                    onChange={(e) =>
                                      updateAnswer(
                                        e.currentTarget.value,
                                        result,
                                        question,
                                        setFieldValue
                                      )
                                    }
                                  />
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Formik
      enableReinitialize={false}
      initialValues={initValue}
      onSubmit={_.debounce((values) => {
        submitResult(values);
      }, 500)}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Form className="listening-test-wrapper">
          <div className="col-lg-10">
            <div className="test-content" id="test-content">
              {/* PARTS NAVIGATION */}
              {renderTrackTabList(exam?.examinationCategories)}

              {/* MAIN CONTENT */}
              <div>
                {isFinish ? (
                  <div>Vui lòng nộp bài khi thời gian đã hết</div>
                ) : (
                  renderTrackContent(
                    exam?.examinationCategories,
                    values,
                    setFieldValue
                  )
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-2 border">
            {/* NUMBER NAVIGATION */}
            {renderQuestionNavigationList(exam?.examinationCategories, values)}

            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#modalId"
              className="btn btn-primary w-100"
            >
              Nộp bài
            </button>
          </div>

          {/* Confirm Dialog */}
          <ConfirmDialog
            text="Bạn có chắc chắn muốn nộp bài không?"
            onConfirm={handleSubmit}
          />
        </Form>
      )}
    </Formik>
  );
}
