import { Outlet } from "react-router-dom";
import Footer from "./Footer/Footer";
export default function MasterContainer() {
  return (
    <>
      <div className="master-container container">
        <Outlet></Outlet>
      </div>
      <Footer />
    </>
  );
}
