import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled(NavLink)``;
const SidebarLabel = styled.span``;
const DropdownLink = styled(NavLink)``;

export default function SubMenuSidebar({ item }) {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  const location = useLocation();
  let activeClasses = "";
  if (item.subNav?.find((x) => location.pathname.includes(x.path))) {
    activeClasses = "active";
  }

  return (
    <>
      {item.path ? (
        <SidebarLink
          className="sidebar-link d-flex"
          to={item.path}
          onClick={showSubnav}
        >
          <div className="dflex">
            {item.icon}
            <SidebarLabel className="ms-3">{item.title}</SidebarLabel>
          </div>
          <div className="menu-icon" style={{ fontSize: "20px" }}>
            {item.subNav && subnav
              ? item.iconOpen
              : item.subNav
              ? item.iconClose
              : null}
          </div>
        </SidebarLink>
      ) : (
        <div
          className={`sidebar-link d-flex ${activeClasses}`}
          onClick={showSubnav}
        >
          <div className="dflex">
            {item.icon}
            <SidebarLabel className="ms-3">{item.title}</SidebarLabel>
          </div>
          <div className="menu-icon" style={{ fontSize: "20px" }}>
            {item.subNav && subnav
              ? item.iconOpen
              : item.subNav
              ? item.iconClose
              : null}
          </div>
        </div>
      )}
      {subnav &&
        item.subNav?.map((item, index) => {
          return (
            <DropdownLink className="submenu d-flex" to={item.path} key={index}>
              {item.icon}
              <SidebarLabel className="sub-item">{item.title}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
}
