import React from "react";
import {
  ExaminationType,
  PartTypes,
  QuestionType,
} from "../../../utils/constants";
import QuestionEditForm from "./QuestionEditForm";
import { Step, Stepper } from "react-form-stepper";
import Input from "../Form/Input";
import { Button } from "react-bootstrap";
import { v4 } from "uuid";
import Select from "../Form/Select";
import RichEditor from "../RichEditor/RichEditor";
import { useEffect } from "react";

export default function QuestionGroupEditForm({
  handleChange,
  handleSubmit,
  setFieldValue,
  prefix,
  index,
  partType,
  examinationType,
  values = {},
  errors = {},
  touched = {},
}) {
  //#region steps
  const [totalSteps, setTotalSteps] = React.useState(values.numQuestions);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState([]);

  useEffect(() => {
    setTotalSteps(values.numQuestions);
  }, [values.numQuestions, setTotalSteps]);

  // Set complete when editing
  useEffect(() => {
    if (values.questions?.length > 0) {
      setCompleted(Array.from({ length: values.questions.length }, () => true));
    }
    setActiveStep(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.id]);

  const isFirstStep = () => {
    return activeStep === 0;
  };

  const isLastStep = () => {
    return activeStep === totalSteps - 1;
  };

  const handleNext = () => {
    setActiveStep((nextStep) => nextStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => {
    if (completed[step] && step !== activeStep) {
      setActiveStep(step);
    }
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const stepper = (
    <Stepper activeStep={activeStep}>
      {Array.from({ length: totalSteps }).map((_, index) => (
        <Step
          key={index}
          completed={completed[index] && activeStep !== index}
          onClick={() => {
            handleStep(index);
          }}
          type="button"
        ></Step>
      ))}
    </Stepper>
  );
  //#endregion

  const createInitQuestion = () => {
    return {
      id: v4(),
      content: "",
      questionNo: 1, //TODO
      answerA: "",
      answerB: "",
      answerC: "",
      answerD: "",
      answer: "",
      note: "",
    };
  };
  const handleNextAndSave = (errors) => {
    if (!errors.questions || !errors.questions[activeStep]) {
      handleSubmit();
      if (completed[activeStep] && values.questions.length > activeStep + 1) {
        handleNext();
      } else {
        const questions = values.questions;
        questions.push(createInitQuestion());
        setFieldValue(`${prefix}.questions`, questions);
        handleComplete();
      }
    }
  };

  const handleChangeNumQuestions = (event) => {
    const newTotalSteps = event.currentTarget.value;
    const questions = values.questions;
    const diff = newTotalSteps - totalSteps;

    if (newTotalSteps > 100 || newTotalSteps < 1 || diff === 0) {
      return;
    }

    if (diff < 0) {
      if (
        !window.confirm(
          "Thay đổi số câu hỏi có thể dẫn đến mất dữ liệu bạn đã nhập. Bạn có chắc chắn muốn thay đổi ko?"
        )
      ) {
        return;
      }
    }

    if (diff > 0 && questions.length === 0) {
      questions.push(createInitQuestion());
    } else if (diff < 0) {
      const deleteCount = -diff - (totalSteps - questions.length);
      if (deleteCount > 0 && questions.length > deleteCount) {
        questions.splice(questions.length - deleteCount, deleteCount);
      }
    }

    handleChange(event);
    if (activeStep + 1 > newTotalSteps) {
      setActiveStep(0);
    }
    setFieldValue(`${prefix}.questions`, questions);
  };

  return (
    <div className="question-group">
      <div className="question-group-header">Section {index + 1}</div>
      <div className="form-group">
        <RichEditor
          label="Mô tả yêu cầu"
          fieldName={`${prefix}.description`}
          required
          setFieldValue={setFieldValue}
          value={values.description}
          error={errors.description}
          touched={touched.description}
          height={200}
        />
      </div>
      {examinationType === ExaminationType.HighSchool &&
        partType === PartTypes.Reading.value && (
          <div className="form-group">
            <Input
              label="Bài đọc"
              name={`${prefix}.specificContent`}
              placeholder="Bài đọc"
              as="textarea"
              required
              error={errors.specificContent}
              touched={touched.specificContent}
              onChange={handleChange}
              rows={4}
            />
          </div>
        )}
      <div className="form-group row">
        {(examinationType === ExaminationType.Reading ||
          examinationType === ExaminationType.Listening) && (
          <div className="col-lg-4">
            <Select
              name={`${prefix}.questionType`}
              label="Loại section"
              onChange={handleChange}
            >
              {Object.values(QuestionType).map((item) => (
                <option key={item.value} value={item.value}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>
        )}
        <div className="col-lg-4">
          <Input
            label="Số câu hỏi (1 - 100)"
            name={`${prefix}.numQuestions`}
            placeholder="Số câu hỏi"
            type="number"
            required
            error={errors.numQuestions}
            touched={touched.numQuestions}
            onChange={handleChangeNumQuestions}
          />
        </div>
      </div>
      {values.numQuestions > 0 && (
        <div>
          {values.numQuestions > 1 && stepper}
          <div>
            <QuestionEditForm
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              values={values.questions[activeStep]}
              prefix={`${prefix}.questions[${activeStep}]`}
              questionType={parseInt(values.questionType)}
              errors={errors.questions && errors.questions[activeStep]}
              touched={touched.questions && touched.questions[activeStep]}
            />
          </div>
        </div>
      )}
      <div className="form-action">
        {values.numQuestions > 0 && (
          <Button
            disabled={isFirstStep()}
            onClick={handleBack}
            className="btn btn-primary btn-elevate"
          >
            Trở lại
          </Button>
        )}
        {values.numQuestions > 0 && (
          <Button
            disabled={
              isLastStep() ||
              (errors.questions !== undefined &&
                errors.questions[activeStep] !== undefined)
            }
            onClick={() => handleNextAndSave(errors, handleSubmit)}
            className="btn btn-primary btn-elevate"
          >
            Tiếp theo
          </Button>
        )}
      </div>
    </div>
  );
}
