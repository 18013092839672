import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import Subheader from "./Subheader/Subheader";
import MasterContainer from "./MasterContainer";

export default function AdminLayout() {
  return (
    <>
      <Header></Header>
      <Sidebar></Sidebar>
      <Subheader />
      <main className="admin-layout">
        <MasterContainer />
      </main>
    </>
  );
}
