import React from "react";
import { useField } from "formik";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control", "form-control-solid"];
  if (touched && errors) {
    classes.push("is-invalid-select");
  }

  if (touched && !errors) {
    classes.push("is-valid-select");
  }

  return classes.join(" ");
};

export default function Select({
  label,
  customFeedbackLabel,
  children,
  required,
  ...props
}) {
  const [field, meta] = useField(props);
  const { touched, error } = meta;
  return (
    <div className='form-group-input'>
      {label && (
        <label>
          {label}
          {required && <span className='input-danger'>*</span>}
        </label>
      )}
      <select
        className={getFieldCSSClasses(touched, error)}
        {...field}
        {...props}
      >
        {children}
      </select>
      {error && touched ? (
        <div className='invalid-error-custom'>{error}</div>
      ) : null}
    </div>
  );
}
