import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";

export default function MainLayout() {
  return (
    <>
      <Header></Header>
      <Sidebar></Sidebar>
      {/* <Navbar></Navbar> */}
      <main className="main-layout">
        <Outlet></Outlet>
      </main>
    </>
  );
}
