import React, { useRef, useEffect, useState } from "react";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { useAuthStore } from "../../../stores/AuthStore";
import { getUserByToken } from "../../../apis/AuthApi";

export default function AuthProvider(props) {
  const didRequest = useRef(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(true);
  const { token, logout, setCurrentUser } = useAuthStore();

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const { data: user } = await getUserByToken();
          if (!user.data.user.id) {
            logout();
          }
          let userRes = user.data.user;
          setCurrentUser(userRes);
        }
      } catch (error) {
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowLoadingIndicator(false);
      }

      return () => (didRequest.current = true);
    };

    if (token) {
      requestUser();
    } else {
      logout();
      setShowLoadingIndicator(false);
    }
    // eslint-disable-next-line
  }, []);

  return showLoadingIndicator ? <LoadingIndicator /> : <>{props.children}</>;
}
