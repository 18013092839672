import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetCourseByIdQuery } from "../../../apis/CourseApi";
import { CourseType } from "../../../utils/constants";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import IeltsListeningLesson from "../../components/StudentCourseParts/IeltsListeningLesson";
import IeltsReadingLesson from "../../components/StudentCourseParts/IeltsReadingLesson";
import IeltsSpeakingLesson from "../../components/StudentCourseParts/IeltsSpeakingLesson";
import IeltsWritingLesson from "../../components/StudentCourseParts/IeltsWritingLesson";
import JuniorLesson from "../../components/StudentCourseParts/JuniorLesson";

export default function StudentCoursePage() {
  const { id } = useParams();
  const { isError, isLoading, data } = useGetCourseByIdQuery(id);
  const course = data?.data;

  const [selectedLesson, setSelectedLesson] = useState(undefined);
  const [resultExam, setResultExam] = useState(undefined);

  useEffect(() => {
    if (course && course.lessons) {
      setSelectedLesson(course.lessons[0]);
    }
  }, [course, setSelectedLesson]);

  const changeLesson = (lesson) => {
    setSelectedLesson(lesson);
    setResultExam(undefined);
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <div>Có lỗi xảy ra, vui lòng thử lại sau.</div>;
  }

  return (
    <div className="student-course">
      {course && (
        <>
          <div className="student-course-lesson card card-custom gutter-b">
            <div className="card-header align-items-center">
              <h3 className="card-title font-weight-bolder text-dark">
                {selectedLesson?.name}
              </h3>
            </div>
            <div className="card-body">
              {course.type === CourseType.Reading.value && (
                <IeltsReadingLesson
                  lesson={selectedLesson}
                  resultExam={resultExam}
                  setResultExam={setResultExam}
                />
              )}
              {course.type === CourseType.Speaking.value && (
                <IeltsSpeakingLesson lesson={selectedLesson} />
              )}
              {course.type === CourseType.Listening.value && (
                <IeltsListeningLesson
                  lesson={selectedLesson}
                  resultExam={resultExam}
                  setResultExam={setResultExam}
                />
              )}
              {course.type === CourseType.Writing.value && (
                <IeltsWritingLesson
                  lesson={selectedLesson}
                  resultExam={resultExam}
                  setResultExam={setResultExam}
                />
              )}
              {course.type === CourseType.Junior.value && (
                <JuniorLesson
                  lesson={selectedLesson}
                  resultExam={resultExam}
                  setResultExam={setResultExam}
                />
              )}
              {/* Discussion */}
              {/* <hr />
              <Discussion/> */}
            </div>
          </div>
          <div className="student-course-list card card-custom gutter-b">
            <div className="card-header align-items-center">
              <h3 className="card-title font-weight-bolder text-dark">
                {course.name}
              </h3>
            </div>
            <div className="card-body">
              {course?.lessons?.map((lesson, index) => (
                <div
                  key={index}
                  className={`lesson-item ${
                    lesson.id === selectedLesson?.id ? "active" : ""
                  }`}
                  onClick={() => changeLesson(lesson, undefined)}
                >
                  {lesson.name}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
