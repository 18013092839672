import { Form, Formik } from "formik";
import _ from "lodash";
import React, { useMemo, useRef, useState } from "react";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import JuniorQuestionList from "./JuniorQuestionList";
import JuniorSection from "./JuniorSection";
import { compareAnswer } from "../../../utils/QuestionUtils";

export default function JuniorParts({ exam, submitResult, isFinish }) {
  const [currentPartIndex, setCurrentPartIndex] = useState(0);
  const questionsRef = useRef([]);

  const initValue = useMemo(() => {
    const value = {
      examinationId: exam?.id,
      mark: 0,
      timeFinish: "",
      examinationQuestionResults: [],
    };
    for (const examCat of exam?.examinationCategories ?? []) {
      for (const group of examCat?.questionGroups ?? []) {
        for (const question of group?.questions ?? []) {
          if (question) {
            value.examinationQuestionResults.push({
              questionId: question.id,
              answer: "",
              isRightAnswer: false,
              mark: question.mark,
            });
            questionsRef.current.push(null);
          }
        }
      }
    }
    return value;
  }, [exam]);

  const scrollToQuestion = (partIndex, questionNo) => {
    setCurrentPartIndex(partIndex);
    setTimeout(() => {
      questionsRef.current[questionNo - 1].scrollIntoView({
        behavior: "smooth",
      });
    }, 50);
  };

  const updateAnswer = (newValue, result, question, setFieldValue) => {
    const index = question.questionNo - 1;
    const questionResult = result.examinationQuestionResults[index];
    questionResult.answer = newValue;
    questionResult.isRightAnswer = question.answers.some((x) =>
      compareAnswer(newValue, x)
    );

    setFieldValue(`examinationQuestionResults[${index}]`, questionResult);
  };

  const renderPartTabList = (parts) => {
    return (
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        {parts?.map((part, index) => (
          <li className="nav-item" role="presentation" key={index}>
            <button
              className={`nav-link ${
                index === currentPartIndex ? "active" : ""
              }`}
              type="button"
              role="tab"
              onClick={() => setCurrentPartIndex(index)}
            >
              {part.name}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const renderPartQuestionNavigation = (part, partIndex, result) => {
    return part?.questionGroups.map((section) => {
      return section.questions.map((question, index) => (
        <span
          className={`list-item ${
            result.examinationQuestionResults[question.questionNo - 1]?.answer
              ? "list-item-answered"
              : ""
          }`}
          key={index}
          onClick={() => scrollToQuestion(partIndex, question.questionNo)}
        >
          {question.questionNo}
        </span>
      ));
    });
  };

  const renderQuestionNavigationList = (parts, result) => {
    return (
      <div className="test-navigation" id="test-navigation">
        <div className="test-navigation-container">
          <div className="test-question-list">
            {parts?.map((part, index) => (
              <div className="test-question-list-part" key={index}>
                <h6 id={`part-${index + 1}`}>{part.name}</h6>
                <div className="test-question-list-wrapper d-flex">
                  {renderPartQuestionNavigation(part, index, result)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderPartContent = (parts, result, setFieldValue) => {
    const renderJuniorQuestionList = (section) => (
      <JuniorQuestionList
        section={section}
        questionsRef={questionsRef}
        result={result}
        setFieldValue={setFieldValue}
        updateAnswer={updateAnswer}
      />
    );

    return (
      <div className="tab-content" id="pills-tabContent">
        {parts?.map((part, index) => (
          <div
            key={index}
            className={`tab-pane fade ${
              index === currentPartIndex ? "show active" : ""
            }`}
          >
            <div className="question-wrapper">
              {part.content && (
                <audio className="audio-listening" controls>
                  <source src={part.content} />
                  <p>Your browser does not support HTML 5 audio element</p>
                </audio>
              )}
              <div className="form-group row">
                <div
                  className={`col-lg-12 d-flex border row overflow-auto ${
                    part.content && "listening"
                  }`}
                >
                  {part.questionGroups?.map((section, sectionIndex) => (
                    <JuniorSection
                      key={sectionIndex}
                      sectionIndex={sectionIndex}
                      section={section}
                      renderJuniorQuestionList={renderJuniorQuestionList}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Formik
      enableReinitialize={false}
      initialValues={initValue}
      onSubmit={_.debounce((values) => {
        submitResult(values);
      }, 500)}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Form className="test-wrapper junior-test-wrapper">
          <div className="row">
            <div className="col-lg-10">
              <div className="test-content" id="test-content">
                {/* PARTS NAVIGATION */}
                {renderPartTabList(exam?.examinationCategories)}

                {/* MAIN CONTENT */}
                <div>
                  {isFinish ? (
                    <div>Vui lòng nộp bài khi thời gian đã hết</div>
                  ) : (
                    renderPartContent(
                      exam?.examinationCategories,
                      values,
                      setFieldValue
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-2 border">
              {/* NUMBER NAVIGATION */}
              {renderQuestionNavigationList(
                exam?.examinationCategories,
                values
              )}

              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#modalId"
                className="btn btn-primary w-100"
              >
                Nộp bài
              </button>
            </div>

            {/* Confirm Dialog */}
            <ConfirmDialog
              text="Bạn có chắc chắn muốn nộp bài không?"
              onConfirm={handleSubmit}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
