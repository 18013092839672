import React from "react";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../../utils/Table/TableSortingUtils";
import MasterLayout from "../../../components/MasterDetail/MasterLayout/MasterLayout";
import { useFindExaminationsMutation } from "../../../../apis/ExaminationApi";
import { ExaminationType } from "../../../../utils/constants";

export default function SpeakingPage() {
  // Table columns
  const columns = [
    {
      dataField: "name",
      text: "Tên đề thi",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "description",
      text: "Mô tả",
    },
  ];

  const speakingFilter = {
    examinationType: ExaminationType.Speaking,
    notExamLesson: true,
    status: 1,
  };

  return (
    <MasterLayout
      title="Đề IELTS speaking"
      columns={columns}
      useFindItemsMutation={useFindExaminationsMutation}
      disabledDelete={true}
      disabledEdit={true}
      additionalDefaultFilter={speakingFilter}
      sortObject={{ dataField: "name", order: "asc" }}
    />
  );
}
