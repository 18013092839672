import React from "react";
import CountdownTimer from "../../../components/CountdownTimer/CountdownTimer";
import SpeakingParts from "../../../components/IeltsParts/SpeakingParts";

export default function SpeakingTestPage() {
  return (
    <div className="card card-custom gutter-b">
      <div className="card-header align-items-center">
        <h3 className="card-title font-weight-bolder text-dark">
          IELTS Speaking - Test...
        </h3>
        <CountdownTimer seconds={15 * 60} />
      </div>
      <div className="card-body">
        <SpeakingParts />
      </div>
    </div>
  );
}
