import { Form, Formik } from "formik";
import React from "react";

export default function SpeakingParts() {
  return (
    <Formik>
      <Form>
        <div className="test-wrapper">
          <div className="row">
            <div className="col-lg-10">
              <div className="test-content" id="test-content">
                {/* PARTS NAVIGATION */}
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="part-1-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#part-1"
                      type="button"
                      role="tab"
                      aria-controls="part-1"
                      aria-selected="true"
                    >
                      Part 1
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="part-2-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#part-2"
                      type="button"
                      role="tab"
                      aria-controls="part-2"
                      aria-selected="false"
                    >
                      Part 2
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="part-3-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#part-3"
                      type="button"
                      role="tab"
                      aria-controls="part-3"
                      aria-selected="false"
                    >
                      Part 3
                    </button>
                  </li>
                </ul>

                {/* MAIN CONTENT */}
                <div className="tab-content" id="pills-tabContent">
                  {/* Part 1 */}
                  <div
                    className="tab-pane fade show active"
                    id="part-1"
                    role="tabpanel"
                    aria-labelledby="part-1-tab"
                  >
                    <div className="question-wrapper">
                      <div className="form-group row">
                        <div className="col-lg-12">
                          <div className="question-container">
                            <ul className="question-list">
                              <li className="question-number mb-3">
                                <strong>Question 1: Do you like sports?</strong>
                                <p>Vocabulary:</p>
                                <ul className="vocab-list">
                                  <li>To find sb/sth + Adj</li>
                                  <li>
                                    To keep fit = to stay in good physical
                                    condition
                                  </li>
                                </ul>
                                <div className="examples">
                                  <ul
                                    className="nav nav-pills mb-3"
                                    id="pills-tab"
                                    role="tablist"
                                  >
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <button
                                        className="nav-link"
                                        id="pills-example-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-question-1"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-question-1"
                                        aria-selected="true"
                                      >
                                        Xem câu trả lời mẫu
                                      </button>
                                    </li>
                                  </ul>
                                  <div
                                    className="tab-content"
                                    id="pills-tabContent"
                                  >
                                    <div
                                      className="tab-pane shadow p-3 mb-3 mt-4 bg-body rounded fade show"
                                      id="pills-question-1"
                                      role="tabpanel"
                                      aria-labelledby="pills-example-tab"
                                    >
                                      "Definitely yes. There are many sports
                                      that I find interesting such as football,
                                      tennis, swimming,... The reason behind
                                      this is that different type of sports
                                      contribute to good health and keep us fit
                                      and active."
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="question-number mb-3">
                                <strong>Question 1: Do you like sports?</strong>
                                <p>Vocabulary:</p>
                                <ul className="vocab-list">
                                  <li>To find sb/sth + Adj</li>
                                  <li>
                                    To keep fit = to stay in good physical
                                    condition
                                  </li>
                                </ul>
                                <div className="examples">
                                  <ul
                                    className="nav nav-pills mb-3"
                                    id="pills-tab"
                                    role="tablist"
                                  >
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <button
                                        className="nav-link"
                                        id="pills-example-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-example"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-example"
                                        aria-selected="true"
                                      >
                                        Xem câu trả lời mẫu
                                      </button>
                                    </li>
                                  </ul>
                                  <div
                                    className="tab-content"
                                    id="pills-tabContent"
                                  >
                                    <div
                                      className="tab-pane shadow p-3 mb-3 mt-4 bg-body rounded fade show"
                                      id="pills-example"
                                      role="tabpanel"
                                      aria-labelledby="pills-example-tab"
                                    >
                                      "Definitely yes. There are many sports
                                      that I find interesting such as football,
                                      tennis, swimming,... The reason behind
                                      this is that different type of sports
                                      contribute to good health and keep us fit
                                      and active."
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Part 2 */}
                  <div
                    className="tab-pane fade"
                    id="part-2"
                    role="tabpanel"
                    aria-labelledby="part-2-tab"
                  >
                    <div className="question-wrapper">
                      <div className="form-group row">
                        <div className="col-lg-12">
                          <div className="question-container">
                            <ul className="question-list">
                              <li className="question-number mb-3">
                                <strong>Question 1: Do you like sports?</strong>
                                <p>Vocabulary:</p>
                                <ul className="vocab-list">
                                  <li>To find sb/sth + Adj</li>
                                  <li>
                                    To keep fit = to stay in good physical
                                    condition
                                  </li>
                                </ul>
                                <div className="examples">
                                  <ul
                                    className="nav nav-pills mb-3"
                                    id="pills-tab"
                                    role="tablist"
                                  >
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <button
                                        className="nav-link"
                                        id="pills-example-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-question-3"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-question-3"
                                        aria-selected="true"
                                      >
                                        Xem câu trả lời mẫu
                                      </button>
                                    </li>
                                  </ul>
                                  <div
                                    className="tab-content"
                                    id="pills-tabContent"
                                  >
                                    <div
                                      className="tab-pane shadow p-3 mb-3 mt-4 bg-body rounded fade show"
                                      id="pills-question-3"
                                      role="tabpanel"
                                      aria-labelledby="pills-example-tab"
                                    >
                                      "Definitely yes. There are many sports
                                      that I find interesting such as football,
                                      tennis, swimming,... The reason behind
                                      this is that different type of sports
                                      contribute to good health and keep us fit
                                      and active."
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Part 3 */}
                  <div
                    className="tab-pane fade"
                    id="part-3"
                    role="tabpanel"
                    aria-labelledby="part-3-tab"
                  >
                    <div className="question-wrapper">
                      <div className="form-group row">
                        <div className="col-lg-12">
                          <div className="question-container">
                            <ul className="question-list">
                              <li className="question-number mb-3">
                                <strong>Question 1: Do you like sports?</strong>
                                <p>Vocabulary:</p>
                                <ul className="vocab-list">
                                  <li>To find sb/sth + Adj</li>
                                  <li>
                                    To keep fit = to stay in good physical
                                    condition
                                  </li>
                                </ul>
                                <div className="examples">
                                  <ul
                                    className="nav nav-pills mb-3"
                                    id="pills-tab"
                                    role="tablist"
                                  >
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <button
                                        className="nav-link"
                                        id="pills-example-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-question-4"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-question-4"
                                        aria-selected="true"
                                      >
                                        Xem câu trả lời mẫu
                                      </button>
                                    </li>
                                  </ul>
                                  <div
                                    className="tab-content"
                                    id="pills-tabContent"
                                  >
                                    <div
                                      className="tab-pane shadow p-3 mb-3 mt-4 bg-body rounded fade show"
                                      id="pills-question-4"
                                      role="tabpanel"
                                      aria-labelledby="pills-example-tab"
                                    >
                                      "Definitely yes. There are many sports
                                      that I find interesting such as football,
                                      tennis, swimming,... The reason behind
                                      this is that different type of sports
                                      contribute to good health and keep us fit
                                      and active."
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
