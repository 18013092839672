import { Field } from "formik";

export default function Input({
  className,
  label,
  type,
  placeholder,
  name,
  error,
  touched,
  required,
  ...props
}) {
  return (
    <div className={`form-group-input ${className ?? ""}`}>
      <label htmlFor={name}>
        {label}
        {required && <span className="input-danger">*</span>}
      </label>
      <Field
        placeholder={placeholder}
        type={type}
        className="form-control"
        name={name}
        {...props}
      />
      {error && touched ? (
        <div className="invalid-error-custom">{error}</div>
      ) : null}
    </div>
  );
}
