import React from "react";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { useMasterUIContext } from "../MasterDetail/MasterLayout/MasterUIContext";
import { useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";

export default function ExcelExportButton() {
  const { queryParams, exportExcelMutation } = useMasterUIContext();

  const { isLoading, isError, error, data, mutate } = exportExcelMutation();

  if (isError) {
    toast.error(error?.response?.data?.message ?? "Xuất file Excel thất bại!", {
      toastId: "id-export-excel-fail",
    });
  }

  const findItems = () => {
    const newQueryParams = { ...queryParams, pageSize: 10000 };
    mutate(newQueryParams);
  };

  const formatDataForExcel = (data) => {
    const formattedData = [
      [
        "Tên học viên",
        "Loại học viên",
        "Tên bài test",
        "Số câu hỏi đúng",
        "Thời điểm thi",
      ],
    ];
    data.forEach((item) => {
      formattedData.push([
        item.studentName,
        item.studentRole,
        item.examinationName,
        `${item.numRightQuestions}/${item.numQuestions}`,
        moment.utc(item.createdDate).local().format("HH:mm DD-MM-YYYY"),
      ]);
    });

    return formattedData;
  };

  useEffect(() => {
    const entities = data?.data?.results;
    if (entities) {
      // Process the received data into a format suitable for Excel
      const formattedData = formatDataForExcel(entities);

      // Generate Excel file
      const ws = XLSX.utils.aoa_to_sheet(formattedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const fileName = "Student_test_result.xlsx";

      // Convert the data to a Blob and prompt the user to save the file
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      FileSaver.saveAs(blob, fileName);
    }
  }, [data]);

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => findItems()}
        disabled={isLoading}
      >
        {isLoading ? <LoadingIndicator /> : "Xuất file Excel"}
      </button>
    </div>
  );
}
