import { Form, Formik } from "formik";
import _, { isEmpty } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Step, Stepper } from "react-form-stepper";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import * as Yup from "yup";
import {
  useEditExaminationMutation,
  useGetExaminationByIdQuery,
} from "../../../apis/ExaminationApi";
import { ExaminationType, StatusType } from "../../../utils/constants";
import JuniorExaminationCategoryEditForm from "../ExaminationPartialForm/Junior/JuniorExaminationCategoryEditForm";
import Input from "../Form/Input";
import Select from "../Form/Select";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import SubmitInterval from "./SubmitInterval";
import { isQuestionValid } from "../../../utils/QuestionUtils";

const ValidateEditSchema = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập tên bài test"),
  timeByMinutes: Yup.number()
    .required()
    .min(1, "Thời gian thi tối thiểu là 1 phút")
    .max(180, "Thời gian thi tối đa là 180 phút"),
  numExaminationCategories: Yup.number()
    .required("Vui lòng nhập số part trong bài test")
    .min(1, "Đề thi phải có tối thiểu 1 part")
    .max(10, "Đề thi chỉ nên có tối đa 10 parts"),
});

const getExamCatErrors = (errors, index) =>
  errors.examinationCategories !== undefined &&
  errors.examinationCategories[index] !== undefined;

export default function AdminJuniorTestForm({
  onCancel,
  onFinish,
  formRef,
  id,
  lessonId,
  roles = "JUNIOR",
}) {
  const isEdit = id !== undefined;
  const {
    isLoading: isGetLoading,
    isError: isGetError,
    isSuccess: isGetSuccess,
    data,
  } = useGetExaminationByIdQuery(id);

  const createInitExaminationCategory = (orderNo) => {
    return {
      id: v4(),
      name: "",
      mark: 1,
      orderNo,
      numQuestionGroups: 0,
      questionGroups: [],
    };
  };

  const createInitExaminationCategories = (records) => {
    const categories = [];
    for (let i = 0; i < records; i++) {
      categories.push(createInitExaminationCategory(i));
    }

    return categories;
  };

  const InitExamination = {
    id: v4(),
    name: "",
    timeByMinutes: 0,
    numQuestions: 0,
    numExaminationCategories: 4,
    examinationCategories: createInitExaminationCategories(4),
    description: "",
    status: 1,
    roles: roles,
    examinationType: ExaminationType.Junior,
    lessonId,
  };

  const initValue = useMemo(
    () => ({ ...InitExamination, ...data?.data }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const [isFinish, setIsFinish] = useState(false);
  const { isLoading, isSuccess, mutate, isError, error } =
    useEditExaminationMutation();

  if (isSuccess && !isError) {
    console.debug("Submit success");
  }

  const customId = "custom-id-yes";
  if (isSuccess && !isError && isFinish) {
    toast.success(
      isEdit ? "Cập nhật dữ liệu thành công!" : "Tạo mới thành công!",
      {
        toastId: customId,
      }
    );

    if (onFinish && isFinish) {
      onFinish();
    }
  } else if (isError && isFinish) {
    console.debug(error);
    toast.error(
      error?.response?.data?.message ??
        (isEdit
          ? "Cập nhật dữ liệu không thành công!"
          : "Tạo mới không thành công!"),
      {
        toastId: customId,
      }
    );
  }

  const stepperRef = useRef(null);
  const scrollToStepper = () => {
    setTimeout(() => {
      stepperRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }, 100);
  };

  //#region steps
  const [totalSteps, setTotalSteps] = React.useState(
    InitExamination.numExaminationCategories
  );
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState([]);

  // Set complete when editing
  useEffect(() => {
    if (isEmpty(completed) && data?.data?.examinationCategories?.length > 0) {
      setCompleted(
        Array.from(
          { length: data.data.examinationCategories.length },
          () => true
        )
      );
    }

    if (
      data?.data?.numExaminationCategories &&
      totalSteps !== data.data.numExaminationCategories
    ) {
      setTotalSteps(data.data.numExaminationCategories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data?.numExaminationCategories]);

  const isFirstStep = () => {
    return activeStep === 0;
  };

  const isLastStep = () => {
    return activeStep === totalSteps - 1;
  };

  const handleNext = () => {
    setActiveStep((nextStep) => nextStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => {
    if (completed[step] && step !== activeStep) {
      setActiveStep(step);
    }
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const stepper = (
    <div ref={(el) => (stepperRef.current = el)}>
      <Stepper activeStep={activeStep}>
        {Array.from({ length: totalSteps }).map((_, index) => (
          <Step
            key={index}
            completed={completed[index] && activeStep !== index}
            onClick={() => handleStep(index)}
            type="button"
          />
        ))}
      </Stepper>
    </div>
  );
  //#endregion

  const handleNextAndPushEmpty = (examinationCategories, setFieldValue) => {
    if (
      completed[activeStep] &&
      examinationCategories.length > activeStep + 1
    ) {
      handleNext();
    } else {
      // examinationCategories.push(createInitExaminationCategory(activeStep + 1));
      // setFieldValue("examinationCategories", examinationCategories);
      handleComplete();
    }
    scrollToStepper();
  };

  const handleChangeNumExaminationCategories = (
    examinationCategories,
    newTotalSteps,
    setFieldValue
  ) => {
    const diff = newTotalSteps - totalSteps;
    if (newTotalSteps > 10 || newTotalSteps < 1 || diff === 0) {
      return;
    }

    if (diff < 0) {
      if (
        !window.confirm(
          "Thay đổi số part có thể dẫn đến mất dữ liệu bạn đã nhập. Bạn có chắc chắn muốn thay đổi ko?"
        )
      ) {
        return;
      }
    }

    //if (diff > 0 && examinationCategories.length === 0) {
    //  examinationCategories.push(createInitExaminationCategory(0));
    //}
    if (diff > 0) {
      const newItems = Array.from({ length: diff }, (_, index) => {
        return createInitExaminationCategory(totalSteps + index);
      });
      examinationCategories.push(...newItems);
    } else if (diff < 0) {
      const deleteCount = -diff - (totalSteps - examinationCategories.length);
      if (deleteCount > 0 && examinationCategories.length > deleteCount) {
        examinationCategories.splice(
          examinationCategories.length - deleteCount,
          deleteCount
        );
      }
    }

    if (activeStep >= newTotalSteps) {
      setActiveStep(newTotalSteps - 1);
    }
    setFieldValue("numExaminationCategories", newTotalSteps);
    setFieldValue("examinationCategories", examinationCategories);
    setTotalSteps(newTotalSteps);
  };

  if (isGetLoading) {
    return <LoadingIndicator />;
  }

  if (isGetError) {
    return <div>Có lỗi xảy ra</div>;
  }

  return (
    isGetSuccess && (
      <>
        <Formik
          initialValues={initValue}
          innerRef={formRef}
          validateOnMount={true}
          validationSchema={ValidateEditSchema}
          onSubmit={_.debounce((values) => {
            if (!isLoading && !isGetLoading) {
              console.debug("Submitting");
              // get invalid questions
              const invalidQuestions = values.examinationCategories.flatMap(
                (category) =>
                  category.questionGroups.flatMap((group) =>
                    group.questions
                      .map((question, index) => ({
                        categoryName: category.name,
                        groupNumber: group.orderNo + 1,
                        questionNumber: index + 1,
                        ...question,
                      }))
                      .filter((question) => !isQuestionValid(group, question))
                  )
              );

              console.debug(invalidQuestions);

              if (invalidQuestions.length > 0) {
                toast.error(
                  "Có lỗi dữ liệu trong bài test: " +
                    invalidQuestions
                      .map(
                        (q) =>
                          `part ${q.categoryName} - section ${q.groupNumber} - câu hỏi ${q.questionNumber}`
                      )
                      .join(", "),
                  {
                    toastId: customId,
                  }
                );
                return;
              }

              mutate(values);
            } else {
              console.debug("Submitting but loading");
            }
          }, 500)}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            errors,
            values,
            touched,
            isValid,
            dirty,
          }) => (
            <Form className="form form-label-right">
              <SubmitInterval handleSubmit={handleSubmit} />
              <div className="form-group row">
                <div className="col-lg-4">
                  <Input
                    label="Tên bài test"
                    name="name"
                    placeholder="Tên bài test"
                    required
                    error={errors.name}
                    touched={touched.name}
                  />
                </div>
                <div className="col-lg-4">
                  <Input
                    label="Thời gian thi (1 - 180 phút)"
                    name="timeByMinutes"
                    placeholder="Thời gian thi"
                    type="number"
                    required
                    error={errors.timeByMinutes}
                    touched={touched.timeByMinutes}
                  />
                </div>
                <div className="col-lg-4">
                  <Input
                    label="Số part trong đề"
                    name="numExaminationCategories"
                    placeholder="Số part"
                    type="number"
                    required
                    error={errors.numExaminationCategories}
                    touched={touched.numExaminationCategories}
                    onChange={(e) => {
                      handleChangeNumExaminationCategories(
                        values.examinationCategories,
                        e.currentTarget.value,
                        setFieldValue
                      );
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-4">
                  <Select name="status" label="Trạng thái">
                    {Object.values(StatusType).map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>

              {values.numExaminationCategories > 0 && (
                <div>
                  {values.numExaminationCategories > 1 && stepper}
                  <div>
                    <JuniorExaminationCategoryEditForm
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      prefix={`examinationCategories[${activeStep}]`}
                      values={values.examinationCategories[activeStep]}
                      errors={getExamCatErrors(errors, activeStep)}
                      touched={
                        touched.examinationCategories &&
                        touched.examinationCategories[activeStep]
                      }
                    />
                  </div>
                </div>
              )}

              <div className="form-action">
                {onCancel && (
                  <Button
                    type="button"
                    onClick={onCancel}
                    className="btn btn-light btn-elevate"
                  >
                    Hủy
                  </Button>
                )}
                {values.numExaminationCategories > 1 && (
                  <Button
                    type="button"
                    disabled={isLoading || isFirstStep()}
                    onClick={handleBack}
                    className="btn btn-primary btn-elevate"
                  >
                    Trở lại
                  </Button>
                )}
                {values.numExaminationCategories > 1 && !isLastStep() && (
                  <Button
                    type="button"
                    disabled={getExamCatErrors(errors, activeStep)}
                    onClick={() =>
                      handleNextAndPushEmpty(
                        values.examinationCategories,
                        setFieldValue
                      )
                    }
                    className="btn btn-primary btn-elevate"
                  >
                    Tiếp theo
                  </Button>
                )}
                {onFinish && (
                  <Button
                    disabled={isLoading || !isValid || !dirty}
                    className="btn btn-primary btn-elevate"
                    onClick={() => {
                      handleSubmit();
                      setIsFinish(true);
                    }}
                  >
                    {isLoading && <LoadingIndicator />}
                    {!isLoading && "Lưu"}
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </>
    )
  );
}
