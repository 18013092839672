import { QuestionType } from "./constants";

export const compareAnswer = (answer, correctAnswer) => {
  const trimmedAnswer = answer.trim().replace(/\.*$/, "").replace(/\s+/g, " ");
  const trimmedCorrectAnswer = correctAnswer
    .trim()
    .replace(/\.*$/, "")
    .replace(/\s+/g, " ");

  return trimmedAnswer.toLowerCase() === trimmedCorrectAnswer.toLowerCase();
};

export const isQuestionValid = (group, question) => {
  const { content, answer, answerA, answerB, answerC, answerD } = question;

  if (group.questionType === QuestionType.MutipleChoice.value) {
    return (
      (content &&
        answer &&
        answer.length > 0 &&
        answerA &&
        answerA.length > 0) ||
      (!content && !answer && !answerA && !answerB && !answerC && !answerD)
    );
  }

  return (content && answer && answer.length > 0) || (!content && !answer);
};
