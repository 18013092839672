import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useAuthStore } from "../../../../stores/AuthStore";
import Logo from "../../Logo/Logo";
import avatar from "../../../../assets/img/avatar-default.png";

export default function Header() {
  const { currentUser, logout } = useAuthStore();

  return (
    <header className='header'>
      <div className='container d-flex'>
        <Link to='/' className='header-logo'>
          <Logo />
        </Link>
        <div className='user'>
          <nav className='navbar navbar-expand navbar-dark p-0'>
            <div className='collapse navbar-collapse' id='collapsibleNavId'>
              <ul className='navbar-nav me-auto d-flex'>
                <li className='nav-item dropdown'>
                  <div
                    className='user-info nav-link dropdown-toggle d-flex text-dark p-3'
                    id='dropdownId'
                    data-bs-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <div className='avatar'>
                      <img src={avatar} alt='avatar' />
                    </div>
                    <div className='login-info mx-2 d-none d-sm-block'>
                      <h6>{currentUser.fullName}</h6>
                    </div>
                  </div>
                  <div
                    className='dropdown-menu p-0 m-0'
                    aria-labelledby='dropdownId'
                  >
                    <NavLink className='dropdown-item' to={currentUser.role === "ADMIN" ? "/admin/my-profile" : "/my-profile"}>
                      Hồ sơ của tôi
                    </NavLink>

                    <NavLink className='dropdown-item' to='#' onClick={logout}>
                      Đăng xuất
                    </NavLink>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}
