import { create } from "zustand";

const initState = {
  breadcrumbs: [],
};

export const useBreadcrumbStore = create((set) => ({
  ...initState,
  setBreadcrumbs: (breadcrumbs) => set(() => ({ breadcrumbs })),
}));
