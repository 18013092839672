import React from "react";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../utils/Table/TableSortingUtils";
import MasterLayout from "../../components/MasterDetail/MasterLayout/MasterLayout";
import ResultColumn from "../../components/MasterDetail/ColumnFormatter/ResultColumn";
import { useFindExaminationResultsMutation } from "../../../apis/ExaminationResultApi";
import DateColumn from "../../components/MasterDetail/ColumnFormatter/DateColumn";
import Select from "../../components/Form/Select";
import { UserRoleList } from "../../../utils/constants";
import Input from "../../components/Form/Input";

export default function AdminResultsPage() {
  // Table columns
  const columns = [
    {
      dataField: "studentName",
      text: "Tên học viên",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "studentRole",
      text: "Loại học viên",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "examinationName",
      text: "Tên bài test",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "numRightQuestions",
      text: "Số câu hỏi đúng",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: ResultColumn,
    },
    // {
    //   dataField: "mark",
    //   text: "Điểm",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    {
      dataField: "createdDate",
      text: "Thời điểm thi",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: DateColumn,
    },
  ];

  const renderAdditionalFilterComponent = (handleChange) => (
    <div className="d-flex flex-row justify-content-between">
      <div className="col-lg-3">
        <Select name="userRole" label="Loại học viên" onChange={handleChange}>
          {UserRoleList.map((item) => (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          ))}
        </Select>
      </div>

      <div className="col-lg-4">
        <Input
          type="date"
          name="fromDate"
          label="Từ ngày"
          format="dd/MM/yyyy"
          onChange={handleChange}
        />
      </div>
      <div  className="col-lg-4">
        <Input
          type="date"
          name="toDate"
          label="Đến ngày"
          onChange={handleChange}
        />
      </div>
    </div>
  );

  return (
    <MasterLayout
      title="Kết quả"
      columns={columns}
      useFindItemsMutation={useFindExaminationResultsMutation}
      disabledAll={true}
      enabledExport={true}
      renderAdditionalFilterComponent={renderAdditionalFilterComponent}
    />
  );
}
