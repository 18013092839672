import React from "react";
import { useNavigate } from "react-router-dom";
import { getTestUrl } from "../../../utils/RouteUtils";

export default function StudentTestCard({ test }) {
  const navigate = useNavigate();
  return (
    <div className="student-test card card-custom border">
      <div className="card-header border-0">
        <h1 className="card-title font-weight-bolder text-dark text-center">
          {test.name}
        </h1>
      </div>

      <div className="card-body pt-0 pb-3 px-3 d-flex flex-column gap-3">
        <div class="row">
          <div class="col">{test.numExaminationCategories} phần thi</div>
          <div class="col">{test.numQuestions} câu hỏi</div>
        </div>
        <div class="row">
          <div class="col">{test.timeByMinutes} phút</div>
        </div>

        <div className="d-flex">
          <button
            type="button"
            class="btn btn-outline-primary btn-block col"
            onClick={() => navigate(getTestUrl(test))}
          >
            Chi tiết
          </button>
        </div>
      </div>
    </div>
  );
}
