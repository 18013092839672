import React from "react";
import { MasterUIProvider } from "./MasterUIContext";
import { MasterCard } from "./MasterCard";

export default function MasterLayout({
  title,
  columns,
  useFindItemsMutation,
  deleteItem,
  deleteItems,
  disabledDelete,
  disabledEdit,
  disabledRead,
  disabledAll,
  additionalBreadcrumbs,
  additionalDefaultFilter,
  renderAdditionalFilterComponent,
  sortObject,
  readLink,
  enabledExport,
}) {
  return (
    <MasterUIProvider
      title={title}
      columns={columns}
      useFindItemsMutation={useFindItemsMutation}
      deleteItem={deleteItem}
      deleteItems={deleteItems}
      disabledDelete={disabledAll || disabledDelete}
      disabledEdit={disabledAll || disabledEdit}
      disabledRead={disabledAll || disabledRead}
      additionalBreadcrumbs={additionalBreadcrumbs}
      additionalDefaultFilter={additionalDefaultFilter}
      renderAdditionalFilterComponent={renderAdditionalFilterComponent}
      sortObject={sortObject}
      readLink={readLink}
      hideActionsColumn={disabledAll}
      enabledExport={enabledExport}
    >
      <MasterCard />
    </MasterUIProvider>
  );
}
