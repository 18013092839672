import { delay } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import {
  useEditLessonMutation,
  useGetLessonByIdQuery,
} from "../../../../apis/LessonApi";
import { useBreadcrumbStore } from "../../../../stores/BreadcrumbStore";
import { CourseType } from "../../../../utils/constants";
import AdminBasicLessonForm from "../../../components/AdminCourseParts/AdminBasicLessonForm";
import AdminListeningLessonForm from "../../../components/AdminCourseParts/AdminListeningLessonForm";
import LoadingIndicator from "../../../components/LoadingIndicator/LoadingIndicator";
import { MasterDetailCard } from "../../../components/MasterDetail/MasterDetailLayout/MasterDetailCard";
import { useGetCourseByIdQuery } from "../../../../apis/CourseApi";
import { useCourseHook } from "../../../../hooks/CourseHooks";
import AdminJuniorLessonForm from "../../../components/AdminCourseParts/AdminJuniorLessonForm";

export default function LessonEditPage() {
  const { courseId, id } = useParams();
  const isEdit = id !== undefined;

  const {
    isLoading: isGetCourseLoading,
    isError: isGetCourseError,
    isSuccess: isGetCourseSuccess,
    data: courseResult,
  } = useGetCourseByIdQuery(courseId, true);
  const courseType = courseResult?.data?.type;

  const {
    isLoading: isGetLoading,
    isError: isGetError,
    isSuccess: isGetSuccess,
    data,
  } = useGetLessonByIdQuery(id);

  const initLesson = {
    id: v4(),
    name: "",
    description: "",
    content: "",
    courseId,
    lessonExaminations: [],
  };
  const initValue = useMemo(
    () => ({
      ...initLesson,
      courseType,
      ...data?.data,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  // set breadcrumbs
  const { courseTitle, courseUrl } = useCourseHook();
  const lessonUrl = `${courseUrl}/${courseId}/lessons`;
  const { setBreadcrumbs } = useBreadcrumbStore();
  useEffect(() => {
    const breadcrumbs = [
      { title: courseTitle, pathname: courseUrl },
      { title: "Bài học", pathname: lessonUrl },
      { title: isEdit ? "Chỉnh sửa" : "Tạo mới", pathname: "" },
    ];
    setBreadcrumbs(breadcrumbs);
  }, [isEdit, courseId, setBreadcrumbs]); // eslint-disable-line react-hooks/exhaustive-deps

  const { isLoading, isSuccess, mutate, isError, error } =
    useEditLessonMutation(isEdit);
  const navigate = useNavigate();
  const returnBack = () => {
    navigate(lessonUrl);
  };
  const customId = "custom-id-yes";
  if (isSuccess && !isError) {
    toast.success(
      isEdit ? "Cập nhật dữ liệu thành công!" : "Tạo mới thành công!",
      {
        toastId: customId,
      }
    );
    delay(() => {
      returnBack();
    }, 1000);
  } else if (isError) {
    toast.error(
      error?.response?.data?.message ??
        (isEdit
          ? "Cập nhật dữ liệu không thành công!"
          : "Tạo mới không thành công!"),
      {
        toastId: customId,
      }
    );
  }

  return (
    <MasterDetailCard title={isEdit ? "Chỉnh sửa bài học" : "Tạo mới bài học"}>
      {(isGetLoading || isGetCourseLoading) && <LoadingIndicator />}
      {(isGetError || isGetCourseError) && <div>Có lỗi xảy ra</div>}
      {isGetSuccess && isGetCourseSuccess && (
        <>
          {courseType === CourseType.Reading.value && (
            <AdminBasicLessonForm
              initValue={initValue}
              isLoading={isLoading}
              onSubmit={mutate}
              onCancel={returnBack}
              editTestParams={
                isEdit && {
                  courseId,
                  lessonId: id,
                  examId: data?.data?.lessonExaminations[0]?.examinationId,
                }
              }
            />
          )}
          {courseType === CourseType.Speaking.value && (
            <AdminBasicLessonForm
              initValue={initValue}
              isLoading={isLoading}
              onSubmit={mutate}
              onCancel={returnBack}
            />
          )}
          {courseType === CourseType.Writing.value && (
            <AdminBasicLessonForm
              initValue={initValue}
              isLoading={isLoading}
              onSubmit={mutate}
              onCancel={returnBack}
            />
          )}
          {courseType === CourseType.Listening.value && (
            <AdminListeningLessonForm
              initValue={initValue}
              isLoading={isLoading}
              onSubmit={mutate}
              onCancel={returnBack}
            />
          )}
          {courseType === CourseType.Junior.value && (
            <AdminJuniorLessonForm
              initValue={initValue}
              isLoading={isLoading}
              onSubmit={mutate}
              onCancel={returnBack}
              isEdit={isEdit}
            />
          )}
        </>
      )}
    </MasterDetailCard>
  );
}
