import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useBreadcrumbStore } from "../../../../stores/BreadcrumbStore";

export default function Subheader() {
  const { breadcrumbs } = useBreadcrumbStore();
  return (
    <div className='subheader'>
      <div className='container'>
        <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2'>
          <li className='breadcrumb-item'>
            <Link to='/admin'>
              <FaHome />
            </Link>
          </li>
          {breadcrumbs.map((item, index) => (
            <li key={`bc${index}`} className='breadcrumb-item'>
              {index === breadcrumbs.length - 1 ? (
                <div>{item.title}</div>
              ) : (
                <Link to={{ pathname: item.pathname }}>{item.title}</Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
