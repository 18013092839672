import React, { useEffect } from "react";
import { YesNoOptions, QuestionType } from "../../../../utils/constants";
import QuestionEditForm from "../QuestionEditForm";
import Input from "../../Form/Input";
import { v4 } from "uuid";
import Select from "../../Form/Select";
import RichEditor from "../../RichEditor/RichEditor";
import { isEmpty } from "lodash";

export default function JuniorQuestionGroupEditForm({
  handleChange,
  setFieldValue,
  prefix,
  sectionIndex,
  values = {},
  errors = {},
  touched = {},
}) {
  // Insert empty question if not exist
  useEffect(() => {
    if (!values) {
      return;
    }

    const totalQuestions = values.numQuestions;
    const currentQuestions = values.questions.length;
    if (totalQuestions > currentQuestions) {
      for (let index = 0; index < totalQuestions; index++) {
        if (values.questions.findIndex((x) => x.orderNo === index) > -1) {
          continue;
        }

        const insertIndex = values.questions.findIndex(
          (x) => x.orderNo > index
        );
        const defaultQuestion = createInitQuestion(index);
        if (insertIndex === -1) {
          values.questions.push(defaultQuestion);
        } else {
          values.questions.splice(insertIndex, 0, defaultQuestion);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.id]);

  const createInitQuestion = () => {
    return {
      id: v4(),
      content: "",
      questionNo: 1, //TODO
      answerA: "",
      answerB: "",
      answerC: "",
      answerD: "",
      answer: "",
      note: "",
    };
  };

  const handleChangeNumQuestions = (event) => {
    const newTotalSteps = event.currentTarget.value;
    const questions = values.questions;
    const diff = newTotalSteps - questions.length;

    if (newTotalSteps > 30 || newTotalSteps < 1 || diff === 0) {
      return;
    }

    if (diff < 0) {
      if (
        !window.confirm(
          "Thay đổi số câu hỏi có thể dẫn đến mất dữ liệu bạn đã nhập. Bạn có chắc chắn muốn thay đổi ko?"
        )
      ) {
        return;
      }
    }

    if (diff > 0) {
      const newItems = Array.from({ length: diff }, (_, index) => {
        return createInitQuestion();
      });
      questions.push(...newItems);
    } else if (diff < 0) {
      const deleteCount = -diff;
      if (deleteCount > 0 && questions.length > deleteCount) {
        questions.splice(questions.length - deleteCount, deleteCount);
      }
    }

    setFieldValue(`${prefix}.numQuestions`, newTotalSteps);
    setFieldValue(`${prefix}.questions`, questions);
  };

  const [hasReading, setHasReading] = React.useState(false);
  useEffect(() => {
    setHasReading(!isEmpty(values.specificContent));
  }, [values.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeHasReading = (e) => {
    setHasReading(e.currentTarget.value === "1");
    setFieldValue(`${prefix}.specificContent`, "");
  };

  return (
    <div className="question-group">
      <div className="question-group-header">Section {sectionIndex + 1}</div>
      <div className="form-group">
        <RichEditor
          label="Mô tả yêu cầu"
          fieldName={`${prefix}.description`}
          required
          setFieldValue={setFieldValue}
          value={values.description}
          error={errors.description}
          touched={touched.description}
          height={200}
        />
      </div>
      <div className="form-group row">
        <div className="col-lg-4">
          <Select
            name="hasReading"
            label="Có bài đọc, điền từ hay không?"
            onChange={handleChangeHasReading}
            value={hasReading ? "1" : "0"}
          >
            {Object.values(YesNoOptions).map((item) => (
              <option key={item.value} value={item.value}>
                {item.name}
              </option>
            ))}
          </Select>
        </div>
      </div>
      {hasReading && (
        <div className="form-group">
          <RichEditor
            label="Bài đọc, điền từ"
            fieldName={`${prefix}.specificContent`}
            required
            setFieldValue={setFieldValue}
            value={values.specificContent}
            error={errors.specificContent}
            touched={touched.specificContent}
            height={200}
          />
        </div>
      )}
      <div className="form-group row">
        <div className="col-lg-4">
          <Select
            name={`${prefix}.questionType`}
            label="Loại câu hỏi"
            onChange={(e) =>
              setFieldValue(
                `${prefix}.questionType`,
                parseInt(e.currentTarget.value)
              )
            }
          >
            {Object.values(QuestionType).map((item) => (
              <option key={item.value} value={item.value}>
                {item.name}
              </option>
            ))}
          </Select>
        </div>
        <div className="col-lg-4">
          <Input
            label="Số câu hỏi"
            name={`${prefix}.numQuestions`}
            placeholder="Số câu hỏi"
            type="number"
            required
            error={errors.numQuestions}
            touched={touched.numQuestions}
            onChange={handleChangeNumQuestions}
          />
        </div>
      </div>
      <div>
        {values.questions.map((item, index) => (
          <QuestionEditForm
            key={index}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            values={item}
            questionIndex={index}
            prefix={`${prefix}.questions[${index}]`}
            questionType={values.questionType}
            errors={errors.questions && errors.questions[index]}
            touched={touched.questions && touched.questions[index]}
          />
        ))}
      </div>
    </div>
  );
}
