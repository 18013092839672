export default function JuniorSection({
  section,
  sectionIndex,
  renderJuniorQuestionList,
}) {
  const renderNormalSection = (section) => (
    <div className="section-content">{renderJuniorQuestionList(section)}</div>
  );

  const renderReadingSection = (section) => (
    <div className="col-lg-12 d-flex border">
      <div className="col-left">
        <div
          className="item unformat-bootstrap-table"
          dangerouslySetInnerHTML={{
            __html: section.specificContent,
          }}
        ></div>
      </div>
      <div className="col-right d-flex row">
        <div className="section-content">
          {renderJuniorQuestionList(section)}
        </div>
      </div>
    </div>
  );

  return (
    <div className="d-flex row">
      <div>
        <h6 className="mt-2 primary-header">Section {sectionIndex + 1}</h6>
        <div
          className="item unformat-bootstrap-table"
          dangerouslySetInnerHTML={{
            __html: section.description,
          }}
        ></div>
      </div>
      {section.specificContent
        ? renderReadingSection(section)
        : renderNormalSection(section)}
    </div>
  );
}
