import { isEmpty } from "lodash";
import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { QuestionType } from "../../../utils/constants";
import { compareAnswer } from "../../../utils/QuestionUtils";

export default function ReadingPartsResult({ result, onReturnBack }) {
  const [currentPassageIndex, setCurrentPassageIndex] = useState(0);

  const questionsRef = useRef([]);
  const scrollToQuestion = (partIndex, questionNo) => {
    setCurrentPassageIndex(partIndex);
    setTimeout(() => {
      questionsRef.current[questionNo - 1].scrollIntoView({
        behavior: "smooth",
      });
    }, 50);
  };

  const renderPassageTabList = (passages) => {
    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
      {passages?.map((_, index) => (
        <li className="nav-item" role="presentation" key={index}>
          <button
            className={`nav-link ${
              index === currentPassageIndex ? "active" : ""
            }`}
            type="button"
            role="tab"
            onClick={() => setCurrentPassageIndex(index)}
          >
            Đoạn văn {index + 1}
          </button>
        </li>
      ))}
    </ul>;
  };

  const renderPassageQuestionNavigation = (passage, passageIndex, result) => {
    return passage?.questionGroups.map((section) => {
      return section.questions.map((question, index) => {
        const questionResult = result.examinationQuestionResults.find(
          (x) => x.questionId === question.id
        );
        let className = "list-item";
        if (questionResult?.answer) {
          if (questionResult.isRightAnswer) {
            className += " list-item-right-answer";
          } else {
            className += " list-item-wrong-answer";
          }
        }

        return (
          <span
            className={className}
            key={index}
            onClick={() => scrollToQuestion(passageIndex, question.questionNo)}
          >
            {question.questionNo}
          </span>
        );
      });
    });
  };

  const renderQuestionNavigationList = (passages, result) => {
    return (
      <div className="test-navigation" id="test-navigation">
        <div className="test-navigation-container">
          <div className="test-question-list">
            {passages?.map((item, index) => (
              <div className="test-question-list-part" key={index}>
                <h6 id={`part-${index + 1}`}>Đoạn văn {index + 1}</h6>
                <div className="test-question-list-wrapper d-flex">
                  {renderPassageQuestionNavigation(item, index, result)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const getClassNames = (result, question, currentAnswer) => {
    const questionResult = result.examinationQuestionResults.find(
      (x) => x.questionId === question.id
    );
    const isSelected =
      questionResult.answer.localeCompare(currentAnswer, undefined, {
        sensitivity: "accent",
      }) === 0;
    const isRightAnswer = question.answers.some(
      (x) => compareAnswer(currentAnswer, x)
    );

    if (isRightAnswer) {
      return "right-answer";
    } else if (isSelected && !questionResult.isRightAnswer) {
      return "wrong-answer";
    } else return "";
  };

  const renderPassageContent = (passages, result) => {
    return (
      <div className="tab-content" id="pills-tabContent">
        {passages?.map((passage, index) => (
          <div
            key={index}
            className={`tab-pane fade ${index === 0 ? "show active" : ""}`}
            id={`part-${index + 1}`}
            role="tabpanel"
            aria-labelledby={`part-${index + 1}-tab`}
          >
            <div className="question-wrapper">
              <div className="form-group row">
                <div className="col-lg-12 d-flex">
                  <div className="col-left">
                    <div
                      className="item unformat-bootstrap-table"
                      dangerouslySetInnerHTML={{
                        __html: passage.content,
                      }}
                    ></div>
                  </div>
                  <div className="col-right d-flex row">
                    {passage.questionGroups?.map((section, sectionIndex) => (
                      <div className="section-content" key={sectionIndex}>
                        <div className="section-header">
                          <h6>Section {sectionIndex + 1}</h6>
                          <div
                            className="unformat-bootstrap-table"
                            dangerouslySetInnerHTML={{
                              __html: section.description,
                            }}
                          ></div>
                        </div>
                        {section.questions?.map((question, questionIndex) => (
                          <div
                            className="question-container"
                            key={questionIndex}
                            id={question.questionNo}
                            ref={(el) =>
                              (questionsRef.current[question.questionNo - 1] =
                                el)
                            }
                          >
                            <div className="question-content">
                              <strong className="question-number">
                                {question.questionNo}
                              </strong>
                              <div
                                className="question"
                                dangerouslySetInnerHTML={{
                                  __html: question.content,
                                }}
                              />
                            </div>
                            {section.questionType ===
                              QuestionType.MutipleChoice.value && (
                              <div>
                                <div className="option">
                                  {question.answerA && (
                                    <div
                                      className={`form-check ${getClassNames(
                                        result,
                                        question,
                                        "A"
                                      )}`}
                                    >
                                      A. {question.answerA}
                                    </div>
                                  )}
                                  {question.answerB && (
                                    <div
                                      className={`form-check ${getClassNames(
                                        result,
                                        question,
                                        "B"
                                      )}`}
                                    >
                                      B. {question.answerB}
                                    </div>
                                  )}
                                  {question.answerC && (
                                    <div
                                      className={`form-check ${getClassNames(
                                        result,
                                        question,
                                        "C"
                                      )}`}
                                    >
                                      C. {question.answerC}
                                    </div>
                                  )}
                                  {question.answerD && (
                                    <div
                                      className={`form-check ${getClassNames(
                                        result,
                                        question,
                                        "D"
                                      )}`}
                                    >
                                      D. {question.answerD}
                                    </div>
                                  )}
                                  {isEmpty(
                                    result.examinationQuestionResults.find(
                                      (x) => x.questionId === question.id
                                    )?.answer
                                  ) && (
                                    <div className="wrong-answer">
                                      Bạn chưa trả lời câu hỏi này
                                    </div>
                                  )}
                                </div>
                                {question.description && (
                                  <>
                                    <div className="description mt-2">
                                      Giải thích:
                                    </div>
                                    <div
                                      className="unformat-bootstrap-table"
                                      dangerouslySetInnerHTML={{
                                        __html: question.description,
                                      }}
                                    ></div>
                                  </>
                                )}
                              </div>
                            )}
                            {section.questionType ===
                              QuestionType.Text.value && (
                              <div>
                                <div className="mt-2">Câu trả lời của bạn:</div>
                                <div
                                  className={
                                    result.examinationQuestionResults.find(
                                      (x) => x.questionId === question.id
                                    )?.isRightAnswer
                                      ? "right-answer"
                                      : "wrong-answer"
                                  }
                                >
                                  {result.examinationQuestionResults.find(
                                    (x) => x.questionId === question.id
                                  ).answer || "Bạn chưa trả lời câu hỏi này"}
                                </div>
                                <div className="mt-2">Câu trả lời đúng:</div>
                                {question.answers?.map((answer, index) => (
                                  <>
                                    {index !== 0 && (
                                      <div className="mt-2">Hoặc:</div>
                                    )}
                                    <div>{answer}</div>
                                  </>
                                ))}
                                {question.description && (
                                  <div className="description">
                                    Giải thích: {question.description}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="test-wrapper">
      <div className="row">
        <div className="col-lg-10">
          <div className="test-content" id="test-content">
            {/* PARTS NAVIGATION */}
            {renderPassageTabList(result.examination?.examinationCategories)}

            {/* MAIN CONTENT */}
            <div className="tab-content" id="pills-tabContent">
              {renderPassageContent(
                result.examination?.examinationCategories,
                result
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-2 border">
          {/* NUMBER NAVIGATION */}
          {renderQuestionNavigationList(
            result.examination?.examinationCategories,
            result
          )}
          {onReturnBack && (
            <Button className="w-100" onClick={onReturnBack}>
              Trở về
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
