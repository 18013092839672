import { Form, Formik } from "formik";
import _, { delay } from "lodash";
import React, { useEffect, useMemo } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useEditCourseMutation,
  useGetCourseByIdQuery,
} from "../../../apis/CourseApi";
import { useBreadcrumbStore } from "../../../stores/BreadcrumbStore";
import { CourseType, StatusType, UserRoles } from "../../../utils/constants";
import Input from "../../components/Form/Input";
import Select from "../../components/Form/Select";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { MasterDetailCard } from "../../components/MasterDetail/MasterDetailLayout/MasterDetailCard";
import { useCourseHook } from "../../../hooks/CourseHooks";

const ValidateEditSchema = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập tên"),
  description: Yup.string(),
});

const InitValue = {
  name: "",
  description: "",
  status: 0,
};

export default function AdminCourseEditPage() {
  const { id } = useParams();
  const isEdit = id !== undefined;

  const { isIelts, courseUrl, courseTitle } = useCourseHook();

  const {
    isLoading: isGetLoading,
    isError: isGetError,
    isSuccess: isGetSuccess,
    data,
  } = useGetCourseByIdQuery(id, true);

  const initValue = useMemo(
    () => ({
      ...InitValue,
      roles: isIelts ? UserRoles.Ielts : UserRoles.Junior,
      type: isIelts ? 0 : 4,
      ...data?.data,
    }),
    [data] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // set breadcrumbs
  const { setBreadcrumbs } = useBreadcrumbStore();
  useEffect(() => {
    const breadcrumbs = [
      {
        title: courseTitle,
        pathname: courseUrl,
      },
      { title: isEdit ? "Chỉnh sửa" : "Tạo mới", pathname: "" },
    ];
    setBreadcrumbs(breadcrumbs);
  }, [isEdit, setBreadcrumbs]); // eslint-disable-line react-hooks/exhaustive-deps

  const { isLoading, isSuccess, mutate, isError, error } =
    useEditCourseMutation();
  const navigate = useNavigate();
  const returnBack = () => {
    navigate(courseUrl);
  };
  const customId = "custom-id-yes";

  if (isSuccess && !isError) {
    toast.success(
      isEdit ? "Cập nhật dữ liệu thành công!" : "Tạo mới thành công!",
      {
        toastId: customId,
      }
    );
    delay(() => {
      returnBack();
    }, 1000);
  } else if (isError) {
    toast.error(
      error?.response?.data?.message ??
        (isEdit
          ? "Cập nhật dữ liệu không thành công!"
          : "Tạo mới không thành công!"),
      {
        toastId: customId,
      }
    );
  }

  return (
    <MasterDetailCard
      title={isEdit ? "Chỉnh sửa khóa học" : "Tạo mới khóa học"}
    >
      {isGetLoading && <LoadingIndicator />}
      {isGetError && <div>Có lỗi xảy ra</div>}
      {isGetSuccess && (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={initValue}
            validateOnMount={true}
            validationSchema={ValidateEditSchema}
            onSubmit={_.debounce((values) => {
              mutate(values);
            }, 500)}
          >
            {({ errors, touched, isValid, dirty }) => (
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-4">
                    <Input
                      label="Tên"
                      name="name"
                      placeholder="Tên khóa học"
                      required
                      error={errors.name}
                      touched={touched.name}
                    />
                  </div>
                  {isIelts && (
                    <div className="col-lg-3">
                      <Select name="type" label="Loại khóa học">
                        {Object.values(CourseType).map(
                          (item) =>
                            item.allowSelect && (
                              <option key={item.value} value={item.value}>
                                {item.name}
                              </option>
                            )
                        )}
                      </Select>
                    </div>
                  )}
                  <div className="col-lg-3">
                    <Select name="status" label="Trạng thái">
                      {Object.values(StatusType).map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="form-group">
                  <Input
                    label="Mô tả"
                    name="description"
                    as="textarea"
                    placeholder="Mô tả"
                    error={errors.description}
                    touched={touched.description}
                  />
                </div>

                <div className="form-action">
                  <Button
                    type="button"
                    onClick={returnBack}
                    className="btn btn-light btn-elevate"
                  >
                    Hủy
                  </Button>
                  <Button
                    disabled={isLoading || !isValid || !dirty}
                    className="btn btn-primary btn-elevate"
                    type="submit"
                  >
                    {isLoading && <LoadingIndicator />}
                    {!isLoading && "Lưu"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </MasterDetailCard>
  );
}
