import { Formik, Form } from "formik";
import * as Yup from "yup";
import _, { delay } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";

import LoadingIndicator from "../../../components/LoadingIndicator/LoadingIndicator";
import Input from "../../../components/Form/Input";
import {
  useEditExaminationMutation,
  useGetExaminationByIdQuery,
} from "../../../../apis/ExaminationApi";
import { MasterDetailCard } from "../../../components/MasterDetail/MasterDetailLayout/MasterDetailCard";
import { useBreadcrumbStore } from "../../../../stores/BreadcrumbStore";
import { v4 } from "uuid";
import { ExaminationType } from "../../../../utils/constants";
import { toast } from "react-toastify";

const QuestionSchema = Yup.object().shape({
  content: Yup.string().required("Vui lòng nhập yêu cầu"),
});

const QuestionGroupSchema = Yup.object().shape({
  numQuestions: Yup.number()
    .min(0, "Tối thiểu 1 task")
    .max(5, "Tối đa 5 tasks"),
  questions: Yup.array().of(QuestionSchema),
});

const ExaminationCategorySchema = Yup.object().shape({
  questionGroups: Yup.array().min(1).of(QuestionGroupSchema),
});

const ValidateEditSchema = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập tên"),
  examinationCategories: Yup.array().min(1).of(ExaminationCategorySchema),
});

export default function WritingTestEditPage() {
  const { id } = useParams();
  const isEdit = id !== undefined;
  const {
    isLoading: isGetLoading,
    isError: isGetError,
    isSuccess: isGetSuccess,
    data,
  } = useGetExaminationByIdQuery(id);

  const initWriting = {
    id: v4(),
    name: "",
    numQuestions: 0,
    examinationType: ExaminationType.Writing,
    numExaminationCategories: 1,
    examinationCategories: [
      {
        id: v4(),
        mark: 0,
        orderNo: 0,
        numQuestionGroups: 1,
        questionGroups: [
          { id: v4(), description: "", numQuestions: 0, questions: [] },
        ],
      },
    ],
    description: "",
    status: 0,
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initValue = useMemo(() => ({ ...initWriting, ...data?.data }), [data]);

  const createInitQuestion = () => {
    return {
      id: v4(),
      description: "",
    };
  };

  // set breadcrumbs
  const { setBreadcrumbs } = useBreadcrumbStore();
  useEffect(() => {
    const breadcrumbs = [
      { title: "Writing", pathname: "/admin/tests/writing" },
      { title: isEdit ? "Chỉnh sửa" : "Tạo mới", pathname: "" },
    ];
    setBreadcrumbs(breadcrumbs);
  }, [isEdit, setBreadcrumbs]);

  const { isLoading, isSuccess, mutate, isError, error } =
    useEditExaminationMutation();
  const navigate = useNavigate();
  const returnBack = () => {
    navigate("/admin/tests/writing");
  };
  const customId = "custom-id-yes";

  if (isSuccess && !isError) {
    toast.success(
      isEdit ? "Cập nhật dữ liệu thành công!" : "Tạo mới thành công!",
      {
        toastId: customId,
      }
    );
    delay(() => {
      returnBack();
    }, 1000);
  } else if (isError) {
    toast.error(
      error?.response?.data?.message ??
        (isEdit
          ? "Cập nhật dữ liệu không thành công!"
          : "Tạo mới không thành công!"),
      {
        toastId: customId,
      }
    );
  }

  // Prefix for question group level
  const prefix = "examinationCategories[0].questionGroups[0]";

  const handleChangeNumQuestions = (values, newValue, setFieldValue) => {
    const diff = newValue - values.numQuestions;
    if (newValue > 5 || newValue < 1 || diff === 0) {
      return;
    }

    if (diff < 0) {
      if (
        !window.confirm(
          "Thay đổi số task có thể dẫn đến mất dữ liệu bạn đã nhập. Bạn có chắc chắn muốn thay đổi ko?"
        )
      ) {
        return;
      }
    }

    const questionGroup = values.examinationCategories[0].questionGroups[0];
    questionGroup.numQuestions = newValue;
    const questions = questionGroup.questions;
    if (diff > 0) {
      const newItems = Array.from({ length: diff }, () => {
        return createInitQuestion();
      });
      questions.push(...newItems);
    } else {
      const deleteCount = -diff;
      questions.splice(questions.length - deleteCount, deleteCount);
    }
    setFieldValue(prefix, questionGroup);
    setFieldValue("numQuestions", newValue);
  };

  const getChildren = (object, index, name) => {
    const examCat =
      object.examinationCategories && object.examinationCategories[0];
    const questionGroup = examCat?.questionGroups && examCat?.questionGroups[0];
    const question =
      questionGroup?.questions && questionGroup?.questions[index];
    return question && question[name];
  };

  return (
    <MasterDetailCard
      title={isEdit ? "Chỉnh sửa bài test writing" : "Tạo mới bài test writing"}
    >
      {(isGetLoading) && <LoadingIndicator />}
      {(isGetError) && <div>Có lỗi xảy ra</div>}
      {isGetSuccess && (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={initValue}
            validateOnMount={true}
            validationSchema={ValidateEditSchema}
            onSubmit={_.debounce((values) => {
              if (!isLoading && !isGetLoading) {
                console.debug("Submitting");
                mutate(values);
              } else {
                console.debug("Submitting but loading");
              }
            }, 500)}
          >
            {({ setFieldValue, errors, values, touched, isValid, dirty }) => (
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-4">
                    <Input
                      label="Tên bài writing"
                      name="name"
                      placeholder="Tên bài writing"
                      required
                      error={errors.name}
                      touched={touched.name}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Input
                      label="Số task"
                      name="numQuestions"
                      placeholder="Số task"
                      type="number"
                      required
                      error={errors.numQuestions}
                      touched={touched.numQuestions}
                      onChange={(e) => {
                        handleChangeNumQuestions(
                          values,
                          e.currentTarget.value,
                          setFieldValue
                        );
                      }}
                    />
                  </div>
                </div>

                {Array.from({ length: values.numQuestions }).map((_, index) => (
                  <div className="question-group" key={index}>
                    <div className="question-group-header">
                      Writing part {index + 1}
                    </div>
                    <div className="form-group">
                      <div className="col-lg-4">
                        <Input
                          label="Thời gian làm (1 - 180 phút)"
                          name={`${prefix}.questions[${index}].timeByMinutes`}
                          placeholder="Thời gian làm"
                          type="number"
                          required
                          error={getChildren(errors, index, "timeByMinutes")}
                          touched={getChildren(touched, index, "timeByMinutes")}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <Input
                        label="Mô tả yêu cầu"
                        name={`${prefix}.questions[${index}].content`}
                        placeholder="Mô tả yêu cầu"
                        as="textarea"
                        required
                        error={getChildren(errors, index, "content")}
                        touched={getChildren(touched, index, "content")}
                      />
                    </div>
                  </div>
                ))}

                <div className="form-action">
                  <Button
                    type="button"
                    onClick={returnBack}
                    className="btn btn-light btn-elevate"
                  >
                    Hủy
                  </Button>
                  <Button
                    disabled={isLoading || !isValid || !dirty}
                    className="btn btn-primary btn-elevate"
                    type="submit"
                  >
                    {isLoading && <LoadingIndicator />}
                    {!isLoading && "Lưu"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </MasterDetailCard>
  );
}
