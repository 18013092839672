import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  NoRecordsFoundMessage,
  getHandlerTableChange,
} from "../../../../utils/Table/TablePaginationUtils";
import { getSelectRow } from "../../../../utils/Table/TableRowSelectionUtils";
import { Pagination } from "../../Pagination/Pagination";
import { SizePerPageList } from "../../../../utils/constants";
import { useMasterUIContext } from "./MasterUIContext";
import { isEmpty } from "lodash";

export function MasterTable() {
  // Master UI Context
  const {
    selectedItemIds,
    setSelectedItemIds,
    queryParams,
    setQueryParams,
    defaultSorted,
    dataProps: {
      columns,
      useFindItemsMutationResult: { isLoading, isError, data, mutate },
      disabledDelete,
    },
  } = useMasterUIContext();

  const entities = data?.data?.results;

  useEffect(() => {
    // clear selections list
    if (!isEmpty(selectedItemIds)) {
      setSelectedItemIds([]);
    }

    // server call by queryParams
    mutate(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, mutate]);

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: data?.data?.rowCount ?? 0,
    sizePerPageList: SizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                keyField="id"
                data={entities == null ? [] : entities}
                columns={columns}
                defaultSorted={defaultSorted}
                onTableChange={getHandlerTableChange(setQueryParams)}
                selectRow={getSelectRow({
                  entities,
                  setSelectedItemIds,
                  disabledSelect: disabledDelete,
                })}
                noDataIndication={
                  <NoRecordsFoundMessage
                    entities={entities}
                    isError={isError}
                    isLoading={isLoading}
                  />
                }
                {...paginationTableProps}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
