import React from 'react'
import { 
  sortCaret,
  headerSortingClasses
} from '../../../../utils/Table/TableSortingUtils';
import { ExaminationType } from '../../../../utils/constants';
import MasterLayout from '../../../components/MasterDetail/MasterLayout/MasterLayout';
import { deleteExamination, deleteExaminations, useFindExaminationsMutation } from '../../../../apis/ExaminationApi';

export default function SpeakingTestsPage() {
  // Table columns
  const columns = [
    {
      dataField: "name",
      text: "Tên",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses ,
    },
    {
      dataField: "numQuestions",
      text: "Số câu hỏi",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ];

  const speakingFilter = {
    examinationType: ExaminationType.Speaking,
  };

  return (
    <MasterLayout
      title='Bài test speaking'
      columns={columns}
      useFindItemsMutation={useFindExaminationsMutation}
      deleteItem={deleteExamination}
      deleteItems={deleteExaminations}
      disabledRead={true}
      additionalDefaultFilter={speakingFilter}
    />
  );
}
