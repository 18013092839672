import React from "react";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../Card/Card";
import { MasterFilter } from "./MasterFilter";
import { MasterTable } from "./MasterTable";
import { MasterGrouping } from "./MasterGrouping";
import { useMasterUIContext } from "./MasterUIContext";
import { Link } from "react-router-dom";
import ExcelExportButton from "../../Export/ExcelExportButton";

export function MasterCard() {
  const {
    selectedItemIds,
    dataProps: { title, disabledEdit, enabledExport },
  } = useMasterUIContext();

  return (
    <Card>
      <CardHeader title={`Danh sách ${title}`}>
        {!disabledEdit && (
          <CardHeaderToolbar>
            <Link to="new">
              <button type="button" className="btn btn-primary">
                Tạo {title}
              </button>
            </Link>
          </CardHeaderToolbar>
        )}
        {enabledExport && (
          <CardHeaderToolbar>
            <ExcelExportButton />
          </CardHeaderToolbar>
        )}
      </CardHeader>
      <CardBody>
        <MasterFilter />
        {selectedItemIds.length > 0 && <MasterGrouping />}
        <MasterTable />
      </CardBody>
    </Card>
  );
}
