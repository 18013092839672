import React from "react";
import MasterLayout from "../../components/MasterDetail/MasterLayout/MasterLayout";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../utils/Table/TableSortingUtils";
import { useFindCoursesMutation } from "../../../apis/CourseApi";
import { StatusType } from "../../../utils/constants";
import AllowReadColumn from "../../components/MasterDetail/ColumnFormatter/AllowReadColumn";

export default function StudentCoursesPage({ role }) {
  // Table columns
  const columns = [
    {
      dataField: "name",
      text: "Tên khóa học",
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: AllowReadColumn,
    },
    {
      dataField: "numLessons",
      text: "Số bài học",
    },
    {
      dataField: "description",
      text: "Mô tả",
    },
  ];

  const statusFilter = {
    status: StatusType.Active.value,
    roles: role,
  };

  return (
    <MasterLayout
      title="Khóa học"
      columns={columns}
      useFindItemsMutation={useFindCoursesMutation}
      disabledAll={true}
      additionalDefaultFilter={statusFilter}
    />
  );
}
