import { Form, Formik } from "formik";
import React, { useMemo, useRef } from "react";
import { QuestionType } from "../../../utils/constants";
import { compareAnswer } from "../../../utils/QuestionUtils";
import Input from "../Form/Input";
import _ from "lodash";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

export default function ReadingParts({ exam, submitResult, isFinish }) {
  const questionsRef = useRef([]);

  const initValue = useMemo(() => {
    const value = {
      examinationId: exam?.id,
      mark: 0,
      timeFinish: "",
      examinationQuestionResults: [],
    };
    for (const examCat of exam?.examinationCategories ?? []) {
      for (const group of examCat?.questionGroups ?? []) {
        for (const question of group?.questions ?? []) {
          if (question) {
            value.examinationQuestionResults.push({
              questionId: question.id,
              answer: "",
              isRightAnswer: false,
              mark: question.mark,
            });
            questionsRef.current.push(null);
          }
        }
      }
    }
    return value;
  }, [exam]);

  const scrollToQuestion = (questionNo) => {
    questionsRef.current[questionNo - 1].scrollIntoView();
  };

  const updateAnswer = (newValue, result, question, setFieldValue) => {
    const index = question.questionNo - 1;
    const questionResult = result.examinationQuestionResults[index];
    questionResult.answer = newValue;
    questionResult.isRightAnswer = question.answers.some((x) =>
      compareAnswer(newValue, x)
    );

    setFieldValue(`examinationQuestionResults[${index}]`, questionResult);
  };

  const renderPassageTabList = (passages) => {
    return (
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        {passages?.map((_, index) => (
          <li className="nav-item" role="presentation" key={index}>
            <button
              className={`nav-link ${index === 0 ? "active" : ""}`}
              id={`part-${index + 1}-tab`}
              data-bs-toggle="pill"
              data-bs-target={`#part-${index + 1}`}
              type="button"
              role="tab"
              aria-controls={`part-${index + 1}`}
              aria-selected="true"
            >
              Đoạn văn {index + 1}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const renderPassageQuestionNavigation = (passage, result) => {
    return passage?.questionGroups.map((section) => {
      return section.questions.map((question, index) => (
        <span
          className={`list-item ${
            result.examinationQuestionResults[question.questionNo - 1]?.answer
              ? "list-item-answered"
              : ""
          }`}
          key={index}
          onClick={() => scrollToQuestion(question.questionNo)}
        >
          {question.questionNo}
        </span>
      ));
    });
  };

  const renderQuestionNavigationList = (passages, result) => {
    return (
      <div className="test-navigation" id="test-navigation">
        <div className="test-navigation-container">
          <div className="test-question-list">
            {passages?.map((item, index) => (
              <div className="test-question-list-part" key={index}>
                <h6 id={`part-${index + 1}`}>Đoạn văn {index + 1}</h6>
                <div className="test-question-list-wrapper d-flex">
                  {renderPassageQuestionNavigation(item, result)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderPassageContent = (passages, result, setFieldValue) => {
    return (
      <div className="tab-content" id="pills-tabContent">
        {passages?.map((passage, index) => (
          <div
            key={index}
            className={`tab-pane fade ${index === 0 ? "show active" : ""}`}
            id={`part-${index + 1}`}
            role="tabpanel"
            aria-labelledby={`part-${index + 1}-tab`}
          >
            <div className="question-wrapper">
              <div className="form-group row">
                <div className="col-lg-12 d-flex border">
                  <div className="col-left">
                    <div
                      className="item unformat-bootstrap-table"
                      dangerouslySetInnerHTML={{
                        __html: passage.content,
                      }}
                    ></div>
                  </div>
                  <div className="col-right d-flex row">
                    {passage.questionGroups?.map((section, sectionIndex) => (
                      <div className="section-content" key={sectionIndex}>
                        <div className="section-header">
                          <h6>Section {sectionIndex + 1}</h6>
                          <div
                            className="unformat-bootstrap-table"
                            dangerouslySetInnerHTML={{
                              __html: section.description,
                            }}
                          ></div>
                        </div>
                        {section.questions?.map((question, questionIndex) => (
                          <div
                            className="question-container"
                            key={questionIndex}
                            id={question.questionNo}
                            ref={(el) =>
                              (questionsRef.current[question.questionNo - 1] =
                                el)
                            }
                          >
                            <div className="question-content">
                              <strong className="question-number">
                                {question.questionNo}
                              </strong>
                              <div
                                className="question"
                                dangerouslySetInnerHTML={{
                                  __html: question.content,
                                }}
                              />
                            </div>
                            {section.questionType ===
                              QuestionType.MutipleChoice.value && (
                              <div className="option">
                                {question.answerA && (
                                  <div className="form-check">
                                    <input
                                      type="radio"
                                      name={`examinationQuestionResults[${
                                        question.questionNo - 1
                                      }].answer`}
                                      value="A"
                                      onChange={() =>
                                        updateAnswer(
                                          "A",
                                          result,
                                          question,
                                          setFieldValue
                                        )
                                      }
                                    />
                                    <label htmlFor="A">
                                      A. {question.answerA}
                                    </label>
                                  </div>
                                )}
                                {question.answerB && (
                                  <div className="form-check">
                                    <input
                                      type="radio"
                                      name={`examinationQuestionResults[${
                                        question.questionNo - 1
                                      }].answer`}
                                      value="B"
                                      onChange={() =>
                                        updateAnswer(
                                          "B",
                                          result,
                                          question,
                                          setFieldValue
                                        )
                                      }
                                    />
                                    <label htmlFor="B">
                                      B. {question.answerB}
                                    </label>
                                  </div>
                                )}
                                {question.answerC && (
                                  <div className="form-check">
                                    <input
                                      type="radio"
                                      name={`examinationQuestionResults[${
                                        question.questionNo - 1
                                      }].answer`}
                                      value="C"
                                      onChange={() =>
                                        updateAnswer(
                                          "C",
                                          result,
                                          question,
                                          setFieldValue
                                        )
                                      }
                                    />
                                    <label htmlFor="C">
                                      C. {question.answerC}
                                    </label>
                                  </div>
                                )}
                                {question.answerD && (
                                  <div className="form-check">
                                    <input
                                      type="radio"
                                      name={`examinationQuestionResults[${
                                        question.questionNo - 1
                                      }].answer`}
                                      value="D"
                                      onChange={() =>
                                        updateAnswer(
                                          "D",
                                          result,
                                          question,
                                          setFieldValue
                                        )
                                      }
                                    />
                                    <label htmlFor="D">
                                      D. {question.answerD}
                                    </label>
                                  </div>
                                )}
                              </div>
                            )}
                            {section.questionType ===
                              QuestionType.Text.value && (
                              <Input
                                name={`examinationQuestionResults[${
                                  question.questionNo - 1
                                }].answer`}
                                onChange={(e) =>
                                  updateAnswer(
                                    e.currentTarget.value,
                                    result,
                                    question,
                                    setFieldValue
                                  )
                                }
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Formik
      enableReinitialize={false}
      initialValues={initValue}
      onSubmit={_.debounce((values) => {
        submitResult(values);
      }, 500)}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Form className="test-wrapper">
          <div className="row">
            <div className="col-lg-10">
              <div className="test-content" id="test-content">
                {/* PARTS NAVIGATION */}
                {renderPassageTabList(exam?.examinationCategories)}

                {/* MAIN CONTENT */}
                <div>
                  {isFinish ? (
                    <div>Vui lòng nộp bài khi thời gian đã hết</div>
                  ) : (
                    renderPassageContent(
                      exam?.examinationCategories,
                      values,
                      setFieldValue
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-2 border">
              {/* NUMBER NAVIGATION */}
              {renderQuestionNavigationList(
                exam?.examinationCategories,
                values
              )}

              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#modalId"
                className="btn btn-primary w-100"
              >
                Nộp bài
              </button>
            </div>

            {/* Confirm Dialog */}
            <ConfirmDialog
              text="Bạn có chắc chắn muốn nộp bài không?"
              onConfirm={handleSubmit}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
