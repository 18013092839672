import { Editor } from "@tinymce/tinymce-react";
import { React, useRef, useState } from "react";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/image";
import "tinymce/plugins/table";
import "tinymce/plugins/accordion";
import "tinymce/models/dom/model";
import { isFileContent } from "../../../utils/FileUtils";
import { isEmpty } from "lodash";

export default function RichEditor({
  setFieldValue,
  fieldName,
  value,
  label,
  required,
  error,
  touched,
  height,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const editorRef = useRef(null);
  const handleChange = () => {
    if (editorRef.current) {
      setFieldValue(fieldName, editorRef.current.getContent());
    }
  };

  const handlePaste = (e) => {
    // Remove font-family styles from pasted content
    e.content = e.content.replace(/font-family\s*:\s*[^;]+;/gi, "");
    e.content = e.content.replace(/font-size\s*:\s*[^;"]+\s*;?/gi, "");
  };

  if (!isEmpty(value) && isFileContent(value)) {
    return null;
  }

  return (
    <>
      {label && (
        <label htmlFor={fieldName}>
          {label}
          {required && <span className="input-danger">*</span>}
        </label>
      )}
      {isLoading && <LoadingIndicator />}
      <Editor
        apiKey={process.env.REACT_APP_TINY_API_KEY}
        onInit={(evt, editor) => {
          editorRef.current = editor;
          editor.on("PastePreProcess", handlePaste);
          setIsLoading(false);
        }}
        value={value}
        onEditorChange={handleChange}
        init={{
          height: height ?? 500,
          menubar: false,
          plugins: ["image", "table", "accordion"],
          paste_preprocess: true, // Enable paste preprocessing
          details_initial_state: "expanded", // for accordion
          details_serialized_state: "expanded", // for accordion
          toolbar:
            "undo redo | blocks | " +
            "bold italic underline forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "accordion image table | removeformat",
          file_picker_types: "image",
          /* and here's our custom image picker*/
          file_picker_callback: (cb, value, meta) => {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");

            input.addEventListener("change", (e) => {
              const file = e.target.files[0];

              const reader = new FileReader();
              reader.addEventListener("load", () => {
                /*
                  Note: Now we need to register the blob in TinyMCEs image blob
                  registry. In the next release this part hopefully won't be
                  necessary, as we are looking to handle it internally.
                */
                const id = "blobid" + new Date().getTime();
                const blobCache = editorRef.current.editorUpload.blobCache;
                const base64 = reader.result.split(",")[1];
                const blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);

                /* call the callback and populate the Title field with the file name */
                cb(blobInfo.blobUri(), { title: file.name });
              });
              reader.readAsDataURL(file);
            });

            input.click();
          },
        }}
      />
      {error && touched ? (
        <div className="invalid-error-custom">{error}</div>
      ) : null}
    </>
  );
}
