import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { isFunction } from "lodash";
import ActionsColumn from "../ColumnFormatter/ActionsColumn";
import { useNavigate } from "react-router-dom";
import { useBreadcrumbStore } from "../../../../stores/BreadcrumbStore";
import { toast } from "react-toastify";

const MasterUIContext = createContext();
export function useMasterUIContext() {
  return useContext(MasterUIContext);
}

export const MasterUIConsumer = MasterUIContext.Consumer;

export function MasterUIProvider({
  children,
  title,
  columns,
  useFindItemsMutation,
  deleteItem,
  deleteItems,
  disabledDelete,
  disabledEdit,
  disabledRead,
  additionalBreadcrumbs,
  additionalDefaultFilter,
  renderAdditionalFilterComponent,
  sortObject,
  readLink,
  hideActionsColumn,
  enabledExport,
}) {
  const navigate = useNavigate();
  const defaultSorted = [
    { dataField: "createdDate", order: "desc", ...sortObject }, // order 'asc' | 'desc'
  ];
  const initialFilter = {
    filter: {
      name: "",
      ...additionalDefaultFilter,
    },
    sortField: defaultSorted.dataField,
    sortOrder: defaultSorted.order,
    pageNumber: 1,
    pageSize: 10,
  };
  const initColumns = [
    ...columns,
    !hideActionsColumn && {
      dataField: "action",
      text: "Hành động",
      formatter: ActionsColumn,
      formatExtraData: {
        onEditClick: (id) => navigate(`edit/${id}`),
        onDeleteClick: (id) => {
          deleteItem(id);
          setTimeout(() => {
            toast.success("Xóa dữ liệu thành công");
            setQueryParams({ ...queryParams });
          }, 500);
        },
        onReadClick: (id) => navigate(`${id}${readLink ?? ""}`),
        disabledDelete,
        disabledEdit,
        disabledRead,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  // set breadcrumbs
  const { setBreadcrumbs } = useBreadcrumbStore();
  useEffect(() => {
    const breadcrumbs = [
      ...(additionalBreadcrumbs ?? []),
      { title: title, pathname: "" },
    ];
    setBreadcrumbs(breadcrumbs);
  }, [additionalBreadcrumbs, setBreadcrumbs, title]);

  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      // if (isEqual(prevQueryParams, nextQueryParams)) {
      //   return prevQueryParams;
      // }

      if (prevQueryParams === nextQueryParams) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const useFindItemsMutationResult = useFindItemsMutation();

  const value = {
    queryParams,
    setQueryParams,
    selectedItemIds,
    setSelectedItemIds,
    defaultSorted,
    exportExcelMutation: useFindItemsMutation,
    renderAdditionalFilterComponent,
    dataProps: {
      title: title.toLowerCase(),
      columns: initColumns,
      useFindItemsMutationResult,
      deleteItems,
      disabledDelete,
      disabledEdit,
      disabledRead,
      enabledExport,
    },
  };

  return (
    <MasterUIContext.Provider value={value}>
      {children}
    </MasterUIContext.Provider>
  );
}
