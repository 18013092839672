import logoImg from "../../../assets/img/logo.png";

export default function Logo() {
  return (
    <div className='logo'>
      <img src={logoImg} alt='logo' />
      <h1>Beverly</h1>
    </div>
  );
}
