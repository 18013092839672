import React from "react";
import { deleteUser, deleteUsers, useFindUsersMutation } from "../../../apis/UserApi";
import {
  headerSortingClasses,
  sortCaret,
} from "../../../utils/Table/TableSortingUtils";
import MasterLayout from "../../components/MasterDetail/MasterLayout/MasterLayout";

export default function AdminUsersPage() {
  // Table columns
  const columns = [
    {
      dataField: "lastName",
      text: "Họ và tên đệm",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "firstName",
      text: "Tên",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "role",
      text: "Quyền",
      sort: false,
    },
  ];

  return (
    <MasterLayout
      title="Tài khoản"
      columns={columns}
      useFindItemsMutation={useFindUsersMutation}
      deleteItem={deleteUser}
      deleteItems={deleteUsers}
      disabledRead={true}
      sortObject={{ dataField: "firstName", order: "asc" }}
    />
  );
}
