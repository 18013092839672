/* Pagination Helprs */
import React from "react";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";

export const sortCaret = (order, column) => {
  if (!order) return <FaSort />;
  else if (order === "asc") return <FaSortUp />;
  else if (order === "desc") return <FaSortDown />;
  return null;
};

export const headerSortingClasses = (
  column,
  sortOrder,
  isLastSorting,
  colIndex
) => (sortOrder === "asc" || sortOrder === "desc" ? "sortable-active" : "");
