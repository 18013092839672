import { Player } from "@lottiefiles/react-lottie-player";
import loadIndicator from "../../../assets/img/loading_indicator.json";

export default function LoadingIndicator() {
  return (
    <Player
      className='loading-indicator'
      autoplay
      loop
      src={loadIndicator}
      resizeMode='center'
    ></Player>
  );
}
