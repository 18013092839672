import { useLocation } from "react-router-dom";
import { RoutingPath, isIeltsCourse } from "../utils/RouteUtils";

export const useCourseHook = () => {
  const location = useLocation();
  const isIelts = isIeltsCourse(location.pathname);
  const courseUrl = `/admin/${
    isIelts ? RoutingPath.Admin.IeltsCourse : RoutingPath.Admin.JuniorCourse
  }`;
  const courseTitle = isIelts ? "Khóa học IELTS" : "Khóa học ôn thi vào lớp 10";

  return {
    isIelts,
    courseTitle,
    courseUrl,
  };
};
