import { useMutation, useQuery } from "react-query";
import baseApi from "./BaseApi";

const COURSE_URL = "/courses";
const FIND_COURSE_URL = `${COURSE_URL}/find`;
const DELETE_COURSES_URL = `${COURSE_URL}/deleteCourses`;

export function useFindCoursesMutation() {
  return useMutation(FIND_COURSE_URL, async (queryParams) => {
    return (await baseApi.post(FIND_COURSE_URL, queryParams)).data;
  });
}

export async function deleteCourse(id) {
  return (await baseApi.delete(`${COURSE_URL}/${id}`)).data;
}

export async function deleteCourses(ids) {
  return (await baseApi.post(DELETE_COURSES_URL, ids)).data;
}

export function useGetCourseByIdQuery(id, skipInclude) {
  return useQuery(`${COURSE_URL}/${id}`, async () => {
    if (id) {
      return (
        await baseApi.get(`${COURSE_URL}/${id}${skipInclude ? `?skipInclude=${skipInclude}` : ""}`)
      ).data;
    } else {
      return null;
    }
  });
}

export function useEditCourseMutation() {
  return useMutation(COURSE_URL, async (course) => {
    if (course.id) {
      return (await baseApi.put(COURSE_URL, course)).data;
    } else {
      return (await baseApi.post(COURSE_URL, course)).data;
    }
  });
}
