import React from "react";
import { MasterDetailCard } from "../../components/MasterDetail/MasterDetailLayout/MasterDetailCard";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
  useEditProfileMutation,
  useGetUserByIdQuery,
} from "../../../apis/UserApi";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import Input from "../../components/Form/Input";
import avatar from "../../../assets/img/avatar-default.png";
import { Button } from "react-bootstrap";
import { useAuthStore } from "../../../stores/AuthStore";
import _ from "lodash";
import { toast } from "react-toastify";

const InitProfile = {
  // Get user by id
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: "",
  // Put user profile
  oldPassword: "",
  newPassword: "",
  rePassword: "",
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(50, "Tên không được quá 50 kí tự")
    .required("Vui lòng nhập tên"),
  lastName: Yup.string()
    .max(50, "Họ không được quá 50 kí tự")
    .required("Vui lòng nhập họ và tên đệm"),
  newPassword: Yup.string()
    .min(6, "Mật khẩu tối thiểu 6 ký tự")
    .max(20, "Mật khẩu tối đa 20 ký tự")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[><?@+'`~^%&*[\]{}.!#|\\"$:;=/(),-])[A-Za-z\d><?@+'`~^%&*[\]{}.!#|\\"$:;=/(),-]{6,}$/,
      "Mật khẩu phải bao gồm chữ in hoa, in thường, số và kí tự"
    ),
  rePassword: Yup.string().oneOf(
    [Yup.ref("newPassword")],
    "Mật khẩu xác nhận không khớp"
  ),
});

export default function ProfilePage() {
  const { currentUser } = useAuthStore();
  const {
    isLoading: isGetLoading,
    isError: isGetError,
    isSuccess: isGetSuccess,
    data,
  } = useGetUserByIdQuery(currentUser.id);

  const { isLoading, isSuccess, mutate } = useEditProfileMutation();

  const customId = "custom-id-yes";

  return (
    <MasterDetailCard title="Thông tin cá nhân">
      <div className="profile-info d-flex">
        <div className="row">
          <div className="col-left">
            <div className="item d-flex flex-column">
              <img className="avatar" src={avatar} alt="avatar" />
              <div className="text-center mt-2">
                <h5>{currentUser.fullName}</h5>
                <p>{currentUser.email}</p>
              </div>
            </div>
          </div>
          <div className="col-right">
            {isGetLoading && <LoadingIndicator />}
            {isGetError && <div>Có lỗi xảy ra</div>}
            {isGetSuccess && (
              <Formik
                enableReinitialize={true}
                initialValues={{ ...InitProfile, ...data?.data }}
                validateOnMount={true}
                validationSchema={validationSchema}
                onSubmit={_.debounce((values) => {
                  mutate(values);
                }, 500)}
              >
                {({ touched, errors, isValid, dirty }) => (
                  <Form className="form form-label-right">
                    <div className="form-group row">
                      <div className="col-lg-6">
                        <Input
                          label="Họ và tên đệm"
                          name="lastName"
                          placeholder="Họ và tên đệm"
                          required
                          error={errors.lastName}
                          touched={touched.lastName}
                        />
                      </div>
                      <div className="col-lg-6">
                        <Input
                          label="Tên"
                          name="firstName"
                          placeholder="Tên"
                          required
                          error={errors.firstName}
                          touched={touched.firstName}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-6">
                        <Input
                          disabled
                          label="Email"
                          name="email"
                          type="email"
                          placeholder="Email"
                          required
                        />
                      </div>
                      <div className="col-lg-6">
                        <Input
                          label="Số điện thoại"
                          name="phone"
                          type="text"
                          placeholder="Số điện thoại"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-12">
                        <Input
                          label="Địa chỉ"
                          name="address"
                          type="text"
                          placeholder="Địa chỉ"
                        />
                      </div>
                    </div>
                    <hr />
                    <h5 className="mb-4">Thay đổi mật khẩu</h5>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <Input
                          label="Mật khẩu hiện tại"
                          name="oldPassword"
                          type="password"
                          placeholder="Mật khẩu hiện tại"
                          // autoComplete = "new-password"
                          error={errors.oldPassword}
                          touched={touched.oldPassword}
                        />
                      </div>
                      <div className="col-lg-4">
                        <Input
                          label="Mật khẩu mới"
                          name="newPassword"
                          type="password"
                          placeholder="Mật khẩu mới"
                          autoComplete="new-password"
                          error={errors.newPassword}
                          touched={touched.newPassword}
                        />
                      </div>
                      <div className="col-lg-4">
                        <Input
                          label="Nhập lại mật khẩu mới"
                          name="rePassword"
                          type="password"
                          placeholder="Nhập lại mật khẩu mới"
                          autoComplete="new-password"
                          error={errors.rePassword}
                          touched={touched.rePassword}
                        />
                      </div>
                    </div>

                    {isSuccess &&
                      toast.success("Thay đổi thông tin thành công!", {
                        toastId: customId,
                      })}
                    <div className="form-action">
                      <Button
                        disabled={isLoading || !isValid || !dirty}
                        type="submit"
                        className="btn btn-primary btn-elevate"
                        // onClick={onEdit}
                      >
                        {isLoading && <LoadingIndicator />}
                        {!isLoading && "Lưu"}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </MasterDetailCard>
  );
}
