import axios from "axios";
import { useAuthStore } from "../stores/AuthStore";

const baseApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

baseApi.interceptors.request.use(
  (config) => {
    const { token } = useAuthStore.getState();
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    config.headers = headers;
    return config;
  },
  (err) => Promise.reject(err)
);

export default baseApi;
