import { useMutation, useQuery } from "react-query";
import baseApi from "./BaseApi";

const USER_URL = "/user";
const FIND_USER_URL = `${USER_URL}/find`;
const DELETE_USER_URL = `${USER_URL}/delete`;
const DELETE_USERS_URL = `${USER_URL}/deleteUsers`;
const EDIT_USER_PROFILE_URL = `${USER_URL}/profile`;

export function useFindUsersMutation() {
  return useMutation(FIND_USER_URL, async (queryParams) => {
    return (await baseApi.post(FIND_USER_URL, queryParams)).data;
  });
}

export async function deleteUser(id) {
  return (await baseApi.post(`${DELETE_USER_URL}/${id}`)).data;
}

export async function deleteUsers(ids) {
  return (await baseApi.post(DELETE_USERS_URL, ids)).data;
}

export function useGetUserByIdQuery(id) {
  return useQuery(`${USER_URL}/${id}`, async () => {
    if (id) {
      return (await baseApi.get(`${USER_URL}/${id}`)).data;
    } else {
      return null;
    }
  });
}

export function useEditUserMutation() {
  return useMutation(USER_URL, async (user) => {
    if (user.id) {
      return (await baseApi.put(USER_URL, user)).data;
    } else {
      return (await baseApi.post(USER_URL, user)).data;
    }
  });
}

export function useEditProfileMutation() {
  return useMutation(USER_URL, async (user) => {
    return (await baseApi.put(EDIT_USER_PROFILE_URL, user)).data;
  });
}
