import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { FaNewspaper, FaRegClock } from 'react-icons/fa';
import { RiNewspaperFill } from 'react-icons/ri';

export default function SpeakingInfoPage() {

  const { id } = useParams();
  //const { isError, isLoading, data } = useGetExaminationByIdQuery(id);
  const navigate = useNavigate();
  const startTest = () => {
    navigate(`/ielts/speaking/${id}/start`);
  }

  return (
    <div className="info-page card card-custom gutter-b">
      <div className="card-header align-items-center">
        <h3 className="card-title font-weight-bolder text-dark">
          IELTS SPEAKING - Test...
        </h3>
      </div>
      <div className="card-body">
        <div>
          <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="test-info-tab"
                data-bs-toggle="pill"
                data-bs-target="#test-info"
                type="button"
                role="tab"
                aria-controls="test-info"
                aria-selected="true"
              >
                Thông tin đề thi
              </button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="test-info"
              role="tabpanel"
              aria-labelledby="test-info-tab"
            >
              <p>
                <b>Bộ đề thi: IELTS SPEAKING Năm...</b>
              </p>
              <div className="general-info mb-4">
                <p>
                  <FaRegClock/>
                  <span>Thời gian làm bài: 11 - 14 phút</span>
                </p>
                <p>
                  <RiNewspaperFill/>
                  <span>3 parts</span>
                </p>
                <p>
                  <FaNewspaper/>
                  <span>... câu hỏi</span>
                </p>
              </div>
              <button className="btn btn-secondary" onClick={startTest}>Luyện tập</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
