import React, { useEffect } from "react";
import _ from "lodash";
import QuestionGroupEditForm from "./QuestionGroupEditForm";
import { Fragment } from "react";
import Select from "../Form/Select";
import Input from "../Form/Input";
import { v4 } from "uuid";
import { ExaminationType, PartTypes } from "../../../utils/constants";
import RichEditor from "../RichEditor/RichEditor";
import { useFileSource } from "../../../hooks/FileHooks";

export default function ExaminationCategoryEditForm({
  handleChange,
  handleSubmit,
  setFieldValue,
  prefix,
  examinationType,
  hideAudioInput,
  values,
  errors = {},
  touched = {},
}) {
  // Insert empty question group if not exist
  useEffect(() => {
    if (!values) {
      return;
    }

    const totalSections = values.numQuestionGroups;
    const currentSections = values.questionGroups.length;
    if (totalSections > currentSections) {
      for (let index = 0; index < totalSections; index++) {
        if (values.questionGroups.findIndex((x) => x.orderNo === index) > -1) {
          continue;
        }

        const insertIndex = values.questionGroups.findIndex(
          (x) => x.orderNo > index
        );
        const defaultQuestionGroup = createInitQuestionGroup(index);
        if (insertIndex === -1) {
          values.questionGroups.push(defaultQuestionGroup);
        } else {
          values.questionGroups.splice(insertIndex, 0, defaultQuestionGroup);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.id]);

  const audioCallback = (result) => setFieldValue(`${prefix}.content`, result);
  const { fileSource, addFile } = useFileSource(
    values?.content,
    audioCallback
  );

  const partType =
    examinationType !== ExaminationType.HighSchool ? null : values?.category;

  const createInitQuestionGroup = (orderNo) => {
    return {
      id: v4(),
      description: "",
      questionType: 0,
      numQuestions: 0,
      orderNo,
      questions: [],
    };
  };

  const handleChangeNumQuestionGroups = (event) => {
    const newValue = event.currentTarget.value;
    const questionGroups = values.questionGroups;
    const diff = newValue - values.numQuestionGroups;

    if (diff < 0) {
      if (
        !window.confirm(
          "Thay đổi số section có thể dẫn đến mất dữ liệu bạn đã nhập. Bạn có chắc chắn muốn thay đổi ko?"
        )
      ) {
        return;
      }
    }

    if (newValue > 10 || newValue < 1 || diff === 0) {
      return;
    } else if (diff > 0) {
      const newItems = Array.from({ length: diff }, (_, index) => {
        return createInitQuestionGroup(values.numQuestionGroups + index);
      });
      questionGroups.push(...newItems);
    } else {
      const deleteCount = -diff;
      questionGroups.splice(questionGroups.length - deleteCount, deleteCount);
    }
    setFieldValue(`${prefix}.questionGroups`, questionGroups);
    handleChange(event);
  };

  return (
    <div className="exam-container">
      <div className="form-group row">
        {examinationType === ExaminationType.HighSchool && (
          <div className="col-lg-4">
            <Select
              name={`${prefix}.category`}
              label="Part"
              onChange={handleChange}
            >
              {PartTypes.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>
        )}
        <div className="col-lg-4">
          <Input
            label="Điểm"
            name={`${prefix}.mark`}
            placeholder="Điểm"
            type="number"
            required
            error={errors.mark}
            touched={touched.mark}
            onChange={handleChange}
          />
        </div>
      </div>
      {examinationType === ExaminationType.Reading && (
        <div className="form-group">
          <RichEditor
            label="Bài đọc"
            fieldName={`${prefix}.content`}
            required
            setFieldValue={setFieldValue}
            value={values.content}
            error={errors.content}
            touched={touched.content}
          />
        </div>
      )}
      {!hideAudioInput && examinationType === ExaminationType.Listening && (
        <>
          <div className="form-group">
            <Input
              label="File nghe"
              name={`${prefix}.listeningFile`}
              placeholder="File nghe"
              type="file"
              accept="audio/*"
              onChange={addFile}
              error={errors.content}
              touched={touched.listeningFile}
            />
          </div>
          {fileSource && (
            <div className="form-group">
              <audio controls src={fileSource} />
            </div>
          )}
        </>
      )}
      <div className="form-group row">
        <div className="col-lg-4">
          <Input
            label="Số section (1 - 10)"
            name={`${prefix}.numQuestionGroups`}
            placeholder="Số section"
            type="number"
            required
            error={errors.numQuestionGroups}
            touched={touched.numQuestionGroups}
            onChange={handleChangeNumQuestionGroups}
          />
        </div>
      </div>
      {!_.isEmpty(values.questionGroups) &&
        values.questionGroups.map((item, index) => (
          <Fragment key={index}>
            <div className="separator"></div>
            <QuestionGroupEditForm
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              setFieldValue={setFieldValue}
              partType={partType}
              examinationType={examinationType}
              prefix={`${prefix}.questionGroups[${index}]`}
              index={index}
              values={item}
              errors={errors.questionGroups && errors.questionGroups[index]}
              touched={touched.questionGroups && touched.questionGroups[index]}
            />
          </Fragment>
        ))}
    </div>
  );
}
