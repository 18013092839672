import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import JuniorQuestionListResult from "./JuniorQuestionListResult";
import JuniorSection from "./JuniorSection";

export default function JuniorPartsResult({ result, onReturnBack }) {
  const [currentPartIndex, setCurrentPartIndex] = useState(0);

  const questionsRef = useRef([]);
  const scrollToQuestion = (partIndex, questionNo) => {
    setCurrentPartIndex(partIndex);
    setTimeout(() => {
      questionsRef.current[questionNo - 1].scrollIntoView({
        behavior: "smooth",
      });
    }, 50);
  };

  const renderPartTabList = (parts) => {
    return (
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        {parts?.map((part, index) => (
          <li className="nav-item" role="presentation" key={index}>
            <button
              className={`nav-link ${
                index === currentPartIndex ? "active" : ""
              }`}
              type="button"
              role="tab"
              onClick={() => setCurrentPartIndex(index)}
            >
              {part.name}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const renderPartQuestionNavigation = (part, partIndex, result) => {
    return part?.questionGroups.map((section) => {
      return section.questions.map((question, index) => {
        const questionResult = result.examinationQuestionResults.find(
          (x) => x.questionId === question.id
        );
        let className = "list-item";
        if (questionResult?.answer) {
          if (questionResult.isRightAnswer) {
            className += " list-item-right-answer";
          } else {
            className += " list-item-wrong-answer";
          }
        }

        return (
          <span
            className={className}
            key={index}
            onClick={() => scrollToQuestion(partIndex, question.questionNo)}
          >
            {question.questionNo}
          </span>
        );
      });
    });
  };

  const renderQuestionNavigationList = (parts, result) => {
    return (
      <div className="test-navigation" id="test-navigation">
        <div className="test-navigation-container">
          <div className="test-question-list">
            {parts?.map((part, index) => (
              <div className="test-question-list-part" key={index}>
                <h6 id={`part-${index + 1}`}>{part.name}</h6>
                <div className="test-question-list-wrapper d-flex">
                  {renderPartQuestionNavigation(part, index, result)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderPartContent = (parts, result) => {
    const renderJuniorQuestionList = (section) => (
      <JuniorQuestionListResult
        section={section}
        questionsRef={questionsRef}
        result={result}
      />
    );
    return (
      <div className="tab-content" id="pills-tabContent">
        {parts?.map((part, index) => (
          <div
            key={index}
            className={`tab-pane fade ${
              index === currentPartIndex ? "show active" : ""
            }`}
          >
            <div className="question-wrapper">
              <div className="form-group row">
                <div className="col-lg-12 d-flex border row overflow-auto">
                  {part.questionGroups?.map((section, sectionIndex) => (
                    <JuniorSection
                      key={sectionIndex}
                      sectionIndex={sectionIndex}
                      section={section}
                      renderJuniorQuestionList={renderJuniorQuestionList}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="test-wrapper">
      <div className="row">
        <div className="col-lg-10">
          <div className="test-content" id="test-content">
            {/* PARTS NAVIGATION */}
            {renderPartTabList(result.examination?.examinationCategories)}

            {/* MAIN CONTENT */}
            <div className="tab-content" id="pills-tabContent">
              {renderPartContent(
                result.examination?.examinationCategories,
                result
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-2 border">
          {/* NUMBER NAVIGATION */}
          {renderQuestionNavigationList(
            result.examination?.examinationCategories,
            result
          )}
          {onReturnBack && (
            <Button className="w-100" onClick={onReturnBack}>
              Trở về
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
