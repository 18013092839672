export const UserRoles = {
  Admin: "ADMIN",
  Ielts: "IELTS",
  Junior: "JUNIOR",
  Junior6: "JUNIOR6",
  Junior7: "JUNIOR7",
};

export const RoleList = [
  { value: "ADMIN", name: "Admin" },
  { value: "IELTS", name: "HS IELTS" },
  { value: "JUNIOR6", name: "HS Lớp 6" },
  { value: "JUNIOR7", name: "HS Lớp 7" },
  { value: "JUNIOR", name: "HS Lớp 9" },
];

export const UserRoleList = [
  { value: "", name: "Tất cả" },
  { value: "IELTS", name: "HS IELTS" },
  { value: "JUNIOR6", name: "HS Lớp 6" },
  { value: "JUNIOR7", name: "HS Lớp 7" },
  { value: "JUNIOR", name: "HS Lớp 9" },
];

export const SizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
];

export const PartTypes = {
  Pronunciation: { value: 0, name: "Pronunciation" },
  UseEnglish: { value: 1, name: "Use of English" },
  Reading: { value: 2, name: "Reading Comprehension" },
  Writing: { value: 3, name: "Writing" },
  Listening: { value: 4, name: "Listening" },
};

export const ExaminationType = {
  HighSchool: 0,
  Reading: 1,
  Writing: 2,
  Listening: 3,
  Speaking: 4,
  Junior: 5,
};

export const QuestionType = {
  MutipleChoice: { value: 0, name: "Trắc nghiệm" },
  Text: { value: 1, name: "Tự luận" },
};

export const StatusType = {
  Pending: { value: 0, name: "Đang cập nhật dữ liệu" },
  Active: { value: 1, name: "Hoàn thành" },
};

export const CourseType = {
  Reading: { value: 0, name: "Reading", allowSelect: true },
  Writing: { value: 1, name: "Writing", allowSelect: true },
  Listening: { value: 2, name: "Listening", allowSelect: true },
  Speaking: { value: 3, name: "Speaking", allowSelect: true },
  Junior: { value: 4, name: "Junior", allowSelect: false },
};

export const YesNoOptions = {
  No: { value: 0, name: "Không" },
  Yes: { value: 1, name: "Có" },
};

export const LessonType = {
  Script: { value: "0", name: "Lý thuyết" },
  Quiz: { value: "1", name: "Bài tập" },
};

export const RomainNumberIndex = [
  "I",
  "II",
  "III",
  "IV",
  "V",
  "VI",
  "VII",
  "VIII",
  "IX",
];
