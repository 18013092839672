import BootstrapTable from "react-bootstrap-table-next";
import { NoRecordsFoundMessage } from "../../../utils/Table/TablePaginationUtils";

export default function StudentLatestResult({ latestResults }) {
  const columns = [
    {
      dataField: "examinationName",
      text: "Tên bài test",
    },
    {
      dataField: "numQuestions",
      text: "Số câu hỏi",
    },
    {
      dataField: "numRightQuestions",
      text: "Số câu đúng",
    },
  ];

  return (
    <div className="card card-custom">
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">
          Kết quả làm bài test gần nhất
        </h3>
      </div>

      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center"
        bootstrap4
        remote
        keyField="id"
        data={latestResults}
        columns={columns}
        noDataIndication={<NoRecordsFoundMessage entities={latestResults} />}
      />
    </div>
  );
}
