import React from "react";
import background from "../../../assets/img/not-found.jpg";

export default function NotFound() {
  return (
    <div
      className="not-found"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <div className="d-flex flex-column flex-row-fluid text-center">
        <h1 className="font-weight-boldest text-white mb-12">Oops...</h1>
        <p className="display-4 font-weight-bold text-white">
          Looks like something went wrong.
          <br />
          We're working on it
        </p>
      </div>
    </div>
  );
}
