import { create } from "zustand";
import { persist } from "zustand/middleware";

const initState = {
  token: "",
  currentUser: undefined,
};

export const useAuthStore = create(
  persist(
    (set) => ({
      ...initState,
      setToken: (token) => set(() => ({ token: token })),
      setCurrentUser: (user) => set(() => ({ currentUser: user })),
      setAuthData: (data) =>
        set(() => ({ token: data.token, currentUser: data.user })),
      logout: () => set(() => initState),
    }),
    { name: "token", partialize: (state) => ({ token: state.token }) }
  )
);
