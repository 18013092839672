import { React } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingIndicator from "../../../components/LoadingIndicator/LoadingIndicator";
import { useGetExaminationResultByIdQuery } from "../../../../apis/ExaminationResultApi";
import JuniorPartsResult from "../../../components/PraceticeTestParts/JuniorPartsResult";

export default function JuniorResultPage({ backUrl = "/junior" }) {
  const { id } = useParams();
  const { isError, isLoading, data } = useGetExaminationResultByIdQuery(id);
  const result = data?.data;

  const navigate = useNavigate();
  const returnBack = () => {
    navigate(backUrl);
  };

  return (
    <div className="test-page card card-custom gutter-b">
      {isLoading && <LoadingIndicator />}
      {isError && <div>Có lỗi xảy ra, vui lòng thử lại sau.</div>}
      {!isLoading && !isError && result && (
        <>
          <div className="card-header align-items-center">
            <h3 className="card-title font-weight-bolder text-dark">
              {result.examination.name}
            </h3>
          </div>
          <div className="card-body">
            <JuniorPartsResult result={result} onReturnBack={returnBack} />
          </div>
        </>
      )}
    </div>
  );
}
