import { Form, Formik } from "formik";
import _ from "lodash";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Input from "../Form/Input";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import RichEditor from "../RichEditor/RichEditor";
import { RoutingPath } from "../../../utils/RouteUtils";

const ValidateEditSchema = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập tên"),
  description: Yup.string(),
});

export default function AdminBasicLessonForm({
  initValue,
  isLoading,
  onSubmit,
  onCancel,
  editTestParams,
}) {
  const navigate = useNavigate();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initValue}
      validateOnMount={true}
      validationSchema={ValidateEditSchema}
      onSubmit={_.debounce((values) => {
        if (!isLoading) {
          onSubmit(values);
        }
      }, 500)}
    >
      {({ setFieldValue, values, errors, touched, isValid, dirty }) => (
        <Form className="form form-label-right">
          <div className="form-group row">
            <div className="col-lg-4">
              <Input
                label="Tên"
                name="name"
                placeholder="Tên bài học"
                required
                error={errors.name}
                touched={touched.name}
              />
            </div>
          </div>
          <div className="form-group">
            <Input
              label="Mô tả"
              name="description"
              as="textarea"
              placeholder="Mô tả"
              error={errors.description}
              touched={touched.description}
            />
          </div>

          <div className="form-group">
            <RichEditor
              label="Lý thuyết"
              fieldName="content"
              required
              setFieldValue={setFieldValue}
              value={values.content}
              error={errors.content}
              touched={touched.content}
            />
          </div>

          <div className="form-action">
            <Button
              type="button"
              onClick={onCancel}
              className="btn btn-light btn-elevate"
            >
              Hủy
            </Button>
            <Button
              disabled={isLoading || !isValid || !dirty}
              className="btn btn-primary btn-elevate"
              type="submit"
            >
              {isLoading && <LoadingIndicator />}
              {!isLoading && "Lưu"}
            </Button>
            {editTestParams && (
              <Button
                disabled={isLoading}
                className="btn btn-primary btn-elevate"
                onClick={() => {
                  if (editTestParams.examId) {
                    navigate(
                      `/admin/${RoutingPath.Admin.IeltsCourse}/${editTestParams.courseId}/lessons/edit/${editTestParams.lessonId}/test/edit/${editTestParams.examId}`
                    );
                  } else {
                    navigate(
                      `/admin/${RoutingPath.Admin.IeltsCourse}/${editTestParams.courseId}/lessons/edit/${editTestParams.lessonId}/test/new`
                    );
                  }
                }}
              >
                {isLoading && <LoadingIndicator />}
                {!isLoading && "Chỉnh sửa phần thực hành"}
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
