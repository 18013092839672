import { useEffect } from "react";

export default function SubmitInterval({ handleSubmit }) {
  useEffect(() => {
    const intervalId = setInterval(() => {
      handleSubmit();
    }, 120000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
