import { Tab, Tabs } from "react-bootstrap";
import ReadingParts from "../IeltsParts/ReadingParts";
import ReadingPartsResult from "../IeltsParts/ReadingPartsResult";
import { useGetExaminationByIdQuery } from "../../../apis/ExaminationApi";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { useEffect, useState } from "react";

export default function IeltsReadingLesson({
  lesson,
  resultExam,
  setResultExam,
}) {
  const [activeTab, setActiveTab] = useState(1);
  useEffect(() => {
    setActiveTab(1);
  }, [lesson, setActiveTab]);

  const {
    isLoading: isGetLoading,
    isError: isGetError,
    isSuccess: isGetSuccess,
    data,
  } = useGetExaminationByIdQuery(lesson?.lessonExaminations[0]?.examinationId);

  return (
    <Tabs activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
      <Tab className="lesson-tab" eventKey="1" title="Script">
        <div
          className="unformat-bootstrap-table"
          dangerouslySetInnerHTML={{
            __html: lesson?.content,
          }}
        ></div>
      </Tab>
      <Tab className="lesson-tab" eventKey="2" title="Quiz">
        {isGetLoading && <LoadingIndicator />}
        {isGetError && <div>Có lỗi xảy ra</div>}
        {isGetSuccess && resultExam ? (
          <ReadingPartsResult
            result={resultExam}
            onReturnBack={() => setResultExam(undefined)}
          />
        ) : (
          data?.data && (
            <ReadingParts
              exam={data.data}
              submitResult={(result) =>
                setResultExam({
                  ...result,
                  examination: data.data,
                })
              }
            />
          )
        )}
      </Tab>
    </Tabs>
  );
}
