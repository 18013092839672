import React, { useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useLoginMutaion } from "../../../apis/AuthApi";
import { useAuthStore } from "../../../stores/AuthStore";
import LoginImage from "../../../assets/img/login.png";
import Input from "../../components/Form/Input";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { toast } from "react-toastify";

const initialValues = {
  email: "",
  password: "",
};

function Login() {
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email không hợp lệ")
      .min(3, "Email tối thiểu 3 ký tự")
      .max(50, "Email tối đa 50 ký tự")
      .required("Vui lòng nhập email"),
    password: Yup.string()
      .min(3, "Mật khẩu tối thiểu 3 ký tự")
      .max(50, "Mật khẩu tối đa 50 ký tự")
      .required("Vui lòng nhập mật khẩu"),
  });

  const navigate = useNavigate();
  const { setAuthData, currentUser } = useAuthStore();
  const { isError, isSuccess, isLoading, mutate, data } = useLoginMutaion();

  useEffect(() => {
    if (isSuccess) {
      setAuthData(data.data);
      navigate("/");
    } else if (isError) {
      toast.error("Đăng nhập không thành công!");
    }
  }, [isSuccess, data, setAuthData, isError, navigate]);

  let location = useLocation();
  if (currentUser) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LoginSchema}
      onSubmit={(values) => mutate(values)}
    >
      {({ touched, errors, handleSubmit }) => (
        <div className="login-form login-signin" id="kt_login_signin_form">
          <div className="container">
            <div className="row">
              <div className="col col-left d-flex">
                <img src={LoginImage} alt="..." />
              </div>
              <div className="col col-right d-flex">
                <div className="item">
                  <h1>Beverly xin chào bạn</h1>
                  <p>
                    Chưa có tài khoản? Vui lòng liên hệ với admin bên mình
                    <Link to="https://www.facebook.com/profile.php?id=100078215913175">
                      <strong>Tại Đây </strong>
                    </Link>
                    nhé!
                  </p>
                  <h2>Đăng nhập</h2>
                  <Form onSubmit={handleSubmit} className="form">
                    <div className="form-group">
                      <Input
                        label="Email"
                        name="email"
                        type="email"
                        placeholder="Email"
                        required
                        error={errors.email}
                        touched={touched.email}
                      />
                    </div>
                    <div className="form-group">
                      <Input
                        label="Mật khẩu"
                        name="password"
                        type="password"
                        placeholder="Mật khẩu"
                        required
                        error={errors.password}
                        touched={touched.password}
                      />
                    </div>
                    {/* <div className='form-group d-flex flex-wrap justify-content-end'>
                      <NavLink
                        to='/auth/forgot-password'
                        className='text-dark-50 text-hover-primary forgot-password'
                        id='kt_login_forgot'
                      >
                        {" "}
                        Forgot Password?
                      </NavLink>
                    </div> */}
                    <div className="form-group">
                      <button
                        type="submit"
                        disabled={isLoading}
                        className="btn btn-primary font-weight-bold w-100"
                      >
                        {!isLoading && "Đăng nhập"}
                        {isLoading && <LoadingIndicator size={25} />}
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default Login;
