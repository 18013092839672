export default function IeltsWritingLesson({ lesson }) {
  return (
    <div
      className="unformat-bootstrap-table"
      dangerouslySetInnerHTML={{
        __html: lesson?.content,
      }}
    ></div>
  );
}
