import React from "react";
import MasterLayout from "../../../components/MasterDetail/MasterLayout/MasterLayout";
import { useFindExaminationsMutation } from "../../../../apis/ExaminationApi";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../../utils/Table/TableSortingUtils";
import AllowReadColumn from "../../../components/MasterDetail/ColumnFormatter/AllowReadColumn";
import { ExaminationType, UserRoles } from "../../../../utils/constants";

export default function JuniorPage({
  roles = UserRoles.Junior,
  title = "Đề ôn thi vào lớp 10",
}) {
  // Table columns
  const columns = [
    {
      dataField: "name",
      text: "Tên đề thi",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: AllowReadColumn,
    },
    {
      dataField: "numExaminationCategories",
      text: "Số phần thi",
    },
    {
      dataField: "numQuestions",
      text: "Số câu hỏi",
    },
  ];

  const juniorFilter = {
    roles,
    examinationType: ExaminationType.Junior,
    notExamLesson: true,
    status: 1,
  };

  return (
    <MasterLayout
      title={title}
      columns={columns}
      useFindItemsMutation={useFindExaminationsMutation}
      disabledAll={true}
      additionalDefaultFilter={juniorFilter}
      sortObject={{ dataField: "name", order: "asc" }}
    />
  );
}
