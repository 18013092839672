import React from "react";
import { useMasterUIContext } from "./MasterUIContext";
import { toast } from "react-toastify";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";

export function MasterGrouping() {
  const {
    selectedItemIds,
    queryParams,
    setQueryParams,
    dataProps: { deleteItems },
  } = useMasterUIContext();

  const handleConfirm = () => {
    deleteItems(selectedItemIds);
    setTimeout(() => {
      toast.success("Xóa dữ liệu thành công");
      setQueryParams({ ...queryParams });
    }, 700);
  };

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                  Số lượng chọn: <b>{selectedItemIds.length}</b>
                </span>
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                data-bs-toggle="modal"
                data-bs-target="#modalId"
              >
                <i className="fa fa-trash"></i> Xóa lựa chọn
              </button>
            </div>
          </div>
        </div>
      </div>

      <ConfirmDialog
        text="Bạn có chắc chắn muốn xóa lựa chọn này hay không?"
        onConfirm={handleConfirm}
      />
    </div>
  );
}
