import React from "react";
import { useGetSummaryExaminationResultByUserQuery } from "../../../apis/ExaminationResultApi";
import avatar from "../../../assets/img/avatar-default.png";
import { useAuthStore } from "../../../stores/AuthStore";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import StudentLatestResult from "./StudentLatestResult";
import StudentTestCard from "../../components/StudentTestParts/StudentTestCard";
import { useGetDashboardExaminationsQuery } from "../../../apis/ExaminationApi";

export default function StudentDashboard() {
  const { currentUser } = useAuthStore();
  const {
    isLoading: isGetLoading,
    isError: isGetError,
    isSuccess: isGetSuccess,
    data,
  } = useGetSummaryExaminationResultByUserQuery();
  const summary = data?.data;

  const {
    isLoading: isGetExamLoading,
    isError: isGetExamError,
    isSuccess: isGetExamSuccess,
    data: examData,
  } = useGetDashboardExaminationsQuery();
  const latestTests = examData?.data;

  return (
    <>
      {/* begin::Dashboard */}
      {/* begin::Row */}
      <div className="row">
        {isGetLoading && <LoadingIndicator />}
        {isGetError && <div>Đã có lỗi xảy ra, vui lòng thử lại sau.</div>}
        {!isGetLoading && isGetSuccess && (
          <>
            <div className="col-xl-4">
              <div className="card card-custom">
                <div className="card-header border-0">
                  <h3 className="card-title font-weight-bolder text-dark">
                    Thông tin của bạn
                  </h3>
                </div>

                {/* Body */}
                <div className="card-body pt-0">
                  <div className="info" style={{ display: "flex" }}>
                    <img src={avatar} alt="avatar" style={{ height: 50 }} />
                    <div style={{ paddingLeft: 8 }}>
                      <div>{currentUser.fullName}</div>
                      <div className="text-muted">{currentUser.email}</div>
                    </div>
                  </div>
                  <div className="pt-4">
                    Số lần làm bài test: {summary.numResults || 0}
                  </div>
                  <div className="pt-2">
                    Điểm cao nhất:{" "}
                    {summary.highestResult
                      ? `${summary.highestResult.numRightQuestions}/${summary.highestResult.numQuestions}`
                      : 0}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <StudentLatestResult latestResults={summary.latestResults} />
            </div>

            <div class="container mt-3">
              <div className="card card-custom">
                {isGetExamLoading && <LoadingIndicator />}
                {isGetExamError && (
                  <div>Đã có lỗi xảy ra, vui lòng thử lại sau.</div>
                )}
                {!isGetExamLoading && isGetExamSuccess && (
                  <div className="card card-custom">
                    <div className="card-header border-0 justify-content-center">
                      <h4 className="card-title font-weight-bolder text-dark">
                        Đề thi mới nhất
                      </h4>
                    </div>
                    <div class="card-body pt-0 pb-3 px-3 row row-cols-1 row-cols-sm-2 row-cols-md-4">
                      {latestTests.map((latestTest) => (
                        <div>
                          <StudentTestCard test={latestTest} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {/* end::Row */}

      {/* end::Dashboard */}
    </>
  );
}
