import { useMutation } from "react-query";
import baseApi from "./BaseApi";

const ME_URL = "/Authenticate/me";
const LOGIN_URL = "/Authenticate/login";

export function getUserByToken() {
  return baseApi.get(ME_URL);
}

export function useLoginMutaion() {
  return useMutation(async (body) => {
    return (await baseApi.post(LOGIN_URL, body)).data;
  });
}
