import React from "react";
import Input from "../Form/Input";

export default function MultipleChoiceForm({
  handleChange,
  prefix,
  errors = {},
  touched = {},
}) {
  return (
    <div className='form form-label-right'>
      <div className='form-group'>
        <Input
          label='Đáp án A'
          name={`${prefix}.answerA`}
          placeholder='Đáp án A'
          required
          error={errors.answerA}
          touched={touched.answerA}
          onChange={handleChange}
        />
      </div>
      <div className='form-group'>
        <Input
          label='Đáp án B'
          name={`${prefix}.answerB`}
          placeholder='Đáp án B'
          error={errors.answerB}
          touched={touched.answerB}
          onChange={handleChange}
        />
      </div>
      <div className='form-group'>
        <Input
          label='Đáp án C'
          name={`${prefix}.answerC`}
          placeholder='Đáp án C'
          error={errors.answerC}
          touched={touched.answerC}
          onChange={handleChange}
        />
      </div>
      <div className='form-group'>
        <Input
          label='Đáp án D'
          name={`${prefix}.answerD`}
          placeholder='Đáp án D'
          error={errors.answerD}
          touched={touched.answerD}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
