import React, { useMemo, useState } from "react";
import { QuestionType } from "../../../utils/constants";
import MultipleChoiceForm from "./MultipleChoiceForm";
import Input from "../Form/Input";
import RichEditor from "../RichEditor/RichEditor";

export default function QuestionEditForm({
  handleChange,
  setFieldValue,
  prefix,
  questionType,
  questionIndex,
  values = {},
  errors = {},
  touched = {},
}) {
  const answers = useMemo(() => {
    return values?.answer.split("/") || [""];
  }, [values?.answer]);

  const [numAnswer, setNumAnswer] = useState(answers.length || 1);

  const handleChangeAnswer = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setFieldValue(`${prefix}.answer`, newAnswers.join("/"), {
      shouldDirty: true,
    });
  };

  return (
    <div>
      <div className="form-group">
        <RichEditor
          label={`Câu hỏi ${questionIndex + 1}`}
          fieldName={`${prefix}.content`}
          required
          setFieldValue={setFieldValue}
          value={values.content}
          error={errors.content}
          touched={touched.content}
          height={200}
        />
      </div>
      {questionType === QuestionType.MutipleChoice.value && (
        <MultipleChoiceForm
          prefix={prefix}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
        />
      )}
      {questionType === QuestionType.Text.value && (
        <div className="form-group row">
          <div className="col-lg-4">
            <Input
              label="Số đáp án (1-10)"
              type="number"
              value={numAnswer}
              onChange={(e) => {
                if (
                  Number(e.target.value) > 0 &&
                  Number(e.target.value) <= 10
                ) {
                  setNumAnswer(Number(e.target.value));
                }
              }}
            />
          </div>
        </div>
      )}
      {questionType === QuestionType.Text.value &&
        Array.from({ length: numAnswer }).map((_, index) => (
          <div className="form-group">
            <Input
              label={`Đáp án ${index + 1}`}
              placeholder={`Đáp án ${index + 1}`}
              required
              value={answers[index]}
              onChange={(e) => handleChangeAnswer(index, e.target.value)}
            />
          </div>
        ))}
      {questionType === QuestionType.MutipleChoice.value && (
        <div className="form-group">
          <Input
            label="Đáp án đúng"
            name={`${prefix}.answer`}
            placeholder="Đáp án đúng"
            required
            error={errors.answer}
            touched={touched.answer}
            handleChange={handleChange}
          />
        </div>
      )}
      <div className="form-group">
        <RichEditor
          label="Giải thích"
          fieldName={`${prefix}.note`}
          setFieldValue={setFieldValue}
          value={values.note}
          error={errors.note}
          touched={touched.note}
          height={150}
        />
      </div>
    </div>
  );
}
