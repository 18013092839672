import React from "react";
import { Button } from "react-bootstrap";
import { FaEdit, FaTrash, FaEye } from "react-icons/fa";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";

export default function ActionsColumn(
  _cellContent,
  row,
  _rowIndex,
  {
    onEditClick,
    onDeleteClick,
    onReadClick,
    disabledDelete,
    disabledEdit,
    disabledRead,
  }
) {
  const handleConfirm = (id) => {
    onDeleteClick(id);
  };

  return (
    <div className="action-column">
      {!disabledRead && (
        <Button
          title="Read"
          variant="light"
          className="btn-sm"
          onClick={() => onReadClick(row.id)}
        >
          <FaEye />
        </Button>
      )}

      {!disabledEdit && (
        <Button
          title="Edit"
          variant="light"
          className="btn-sm"
          onClick={() => onEditClick(row.id)}
        >
          <FaEdit />
        </Button>
      )}

      {!disabledDelete && (
        <Button
          title="Delete"
          variant="light"
          className="btn-sm"
          data-bs-toggle="modal"
          data-bs-target={`#id${row.id}`}
        >
          <FaTrash />
        </Button>
      )}

      <ConfirmDialog
        text="Bạn có chắc chắn muốn xóa lựa chọn này hay không?"
        onConfirm={handleConfirm}
        id={row.id}
      />
    </div>
  );
}
