import { isEmpty } from "lodash";
import { QuestionType } from "../../../utils/constants";
import { compareAnswer } from "../../../utils/QuestionUtils";

export default function JuniorQuestionListResult({
  section,
  questionsRef,
  result,
}) {
  const getClassNames = (result, question, currentAnswer) => {
    const questionResult = result.examinationQuestionResults.find(
      (x) => x.questionId === question.id
    );
    const isSelected =
      questionResult.answer.localeCompare(currentAnswer, undefined, {
        sensitivity: "accent",
      }) === 0;
    const isRightAnswer = question.answers.some((x) =>
      compareAnswer(currentAnswer, x)
    );

    if (isRightAnswer) {
      return "right-answer";
    } else if (isSelected && !questionResult.isRightAnswer) {
      return "wrong-answer";
    } else return "";
  };

  return section.questions?.map((question, questionIndex) => (
    <div
      className="question-container"
      key={questionIndex}
      id={question.questionNo}
      ref={(el) => (questionsRef.current[question.questionNo - 1] = el)}
    >
      <div className="question-content">
        <strong className="question-number">{question.questionNo}</strong>
        <div
          className="question"
          dangerouslySetInnerHTML={{
            __html: question.content,
          }}
        />
      </div>
      {section.questionType === QuestionType.MutipleChoice.value && (
        <div>
          <div className="option">
            {question.answerA && (
              <div
                className={`form-check ${getClassNames(result, question, "A")}`}
              >
                A. {question.answerA}
              </div>
            )}
            {question.answerB && (
              <div
                className={`form-check ${getClassNames(result, question, "B")}`}
              >
                B. {question.answerB}
              </div>
            )}
            {question.answerC && (
              <div
                className={`form-check ${getClassNames(result, question, "C")}`}
              >
                C. {question.answerC}
              </div>
            )}
            {question.answerD && (
              <div
                className={`form-check ${getClassNames(result, question, "D")}`}
              >
                D. {question.answerD}
              </div>
            )}
            {isEmpty(
              result.examinationQuestionResults.find(
                (x) => x.questionId === question.id
              )?.answer
            ) && (
              <div className="wrong-answer">Bạn chưa trả lời câu hỏi này</div>
            )}
          </div>
          {question.description && (
            <>
              <div className="description mt-2">Giải thích:</div>
              <div
                className="unformat-bootstrap-table"
                dangerouslySetInnerHTML={{
                  __html: question.description,
                }}
              ></div>
            </>
          )}
        </div>
      )}
      {section.questionType === QuestionType.Text.value && (
        <div>
          <div className="mt-2">Câu trả lời của bạn:</div>
          <div
            className={
              result.examinationQuestionResults.find(
                (x) => x.questionId === question.id
              )?.isRightAnswer
                ? "right-answer"
                : "wrong-answer"
            }
          >
            {result.examinationQuestionResults.find(
              (x) => x.questionId === question.id
            ).answer || "Bạn chưa trả lời câu hỏi này"}
          </div>
          <div className="mt-2">Câu trả lời đúng:</div>
          {question.answers?.map((answer, index) => (
            <>
              {index !== 0 && <div className="mt-2">Hoặc:</div>}
              <div>{answer}</div>
            </>
          ))}
          {question.description && (
            <div className="description">
              Giải thích: {question.description}
            </div>
          )}
        </div>
      )}
    </div>
  ));
}
