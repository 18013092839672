import React from "react";
import { Formik } from "formik";
import { cloneDeep, isEqual } from "lodash";
import { useMasterUIContext } from "./MasterUIContext";
import { Button } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import Input from "../../Form/Input";

const prepareFilter = (queryParams, values) => {
  const { searchText, ...props } = values;
  const newQueryParams = { ...queryParams };
  let filter = { ...cloneDeep(queryParams.filter), ...props };
  // Filter by all fields
  filter.name = searchText;

  newQueryParams.filter = filter;
  return newQueryParams;
};

export function MasterFilter() {
  // Master UI Context
  const {
    queryParams,
    setQueryParams,
    renderAdditionalFilterComponent,
    dataProps: {
      useFindItemsMutationResult: { isLoading },
    },
  } = useMasterUIContext();

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(queryParams, values);
    if (!isEqual(newQueryParams, queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          searchText: "",
        }}
        onSubmit={(values) => {
          if (!isLoading) {
            applyFilter(values);
          }
        }}
      >
        {({ handleSubmit, handleChange }) => (
          <form
            onSubmit={handleSubmit}
            className="form form-label-right filter-form"
          >
            <div className="form-group row">
              <div className="col-lg-6">
                <div className="d-flex flex-column gap-3">
                  <Input
                    type="text"
                    name="searchText"
                    label="Tìm kiếm"
                    placeholder="Tìm kiếm theo tên"
                  />
                  {renderAdditionalFilterComponent &&
                    renderAdditionalFilterComponent(handleChange)}
                </div>
              </div>
              <div className="filter-button">
                <Button type="submit" variant="light" disabled={isLoading}>
                  <FaSearch />
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
