import { delay } from "lodash";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useBreadcrumbStore } from "../../../../stores/BreadcrumbStore";
import { MasterDetailCard } from "../../../components/MasterDetail/MasterDetailLayout/MasterDetailCard";
import AdminJuniorTestForm from "../../../components/AdminTestParts/AdminJuniorTestForm";

export default function JuniorTestEditPage() {
  const { id } = useParams();
  const isEdit = id !== undefined;

  // set breadcrumbs
  const { setBreadcrumbs } = useBreadcrumbStore();
  const backUrl = "/admin/tests/junior6";
  useEffect(() => {
    const breadcrumbs = [
      { title: "Bài test lớp 6", pathname: backUrl },
      { title: isEdit ? "Chỉnh sửa" : "Tạo mới", pathname: "" },
    ];
    setBreadcrumbs(breadcrumbs);
  }, [isEdit, setBreadcrumbs]);

  const navigate = useNavigate();
  const returnBack = () => {
    navigate(backUrl);
  };

  return (
    <MasterDetailCard
      title={
        isEdit
          ? "Chỉnh sửa bài test lớp 6"
          : "Tạo mới bài test lớp 6"
      }
    >
      <AdminJuniorTestForm
        onCancel={returnBack}
        onFinish={() =>
          delay(() => {
            returnBack();
          }, 1000)
        }
        id={id}
        roles="JUNIOR6"
      />
    </MasterDetailCard>
  );
}
