import React, { useEffect, useRef, useState } from "react";

const formatTime = (time) => {
  let hours = Math.floor(time / (60 * 60));
  let minutes = Math.floor(time / 60 - hours * 60);
  let seconds = Math.floor(time - 60 * (hours * 60 + minutes));

  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;
  if (hours < 10) hours = "0" + hours;

  return hours + ":" + minutes + ":" + seconds;
};

export default function CountdownTimer({ seconds, onFinish }) {
  const [countdownTimer, setCountdownTimer] = useState(seconds);
  const timerId = useRef();

  useEffect(() => {
    timerId.current = setInterval(() => {
      setCountdownTimer((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(timerId.current);
  }, []);

  useEffect(() => {
    if (countdownTimer <= 0) {
      clearInterval(timerId.current);
      alert("Đã hết thời gian làm bài!");
      onFinish && onFinish();
    }
  }, [countdownTimer, onFinish]);
  return (
    <div className="my-2 d-flex align-items-center">
      Thời gian làm bài:
      <div className="count-down text-center mx-2">
        <button
          className="btn btn-secondary font-weight-bold"
          style={{ fontSize: "20px" }}
        >
          {formatTime(countdownTimer)}
        </button>
      </div>
    </div>
  );
}
