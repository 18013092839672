import { Navigate, useLocation } from "react-router-dom";
import { useAuthStore } from "../../../stores/AuthStore";
import { UserRoles } from "../../../utils/constants";
import NotFound from "../../pages/NotFound/NotFound";

export default function PrivateRoute({ children, roles }) {
  let location = useLocation();
  const { currentUser } = useAuthStore();
  const isAuthenticated = currentUser !== undefined;
  const userHasRequiredRole =
    currentUser && roles.includes(currentUser.role) ? true : false;

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (isAuthenticated && !userHasRequiredRole) {
    if (
      currentUser.role === UserRoles.Admin &&
      !location.pathname.includes("/admin")
    ) {
      return <Navigate to="/admin" state={{ from: location }} />;
    } else if (
      currentUser.role !== UserRoles.Admin &&
      location.pathname.includes("/admin")
    ) {
      return <Navigate to="/" state={{ from: location }} />;
    } else {
      return <NotFound />;
    }
  }

  return children;
}
