import React from "react";
import MasterLayout from "../../../components/MasterDetail/MasterLayout/MasterLayout";
import { useFindExaminationsMutation } from "../../../../apis/ExaminationApi";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../../utils/Table/TableSortingUtils";
import AllowReadColumn from "../../../components/MasterDetail/ColumnFormatter/AllowReadColumn";
import { ExaminationType } from "../../../../utils/constants";

export default function ReadingPage() {
  // Table columns
  const columns = [
    {
      dataField: "name",
      text: "Tên bài test",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: AllowReadColumn,
    },
    {
      dataField: "numQuestions",
      text: "Số câu hỏi",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "description",
      text: "Mô tả",
    },
  ];

  const listeningFilter = {
    examinationType: ExaminationType.Listening,
    notExamLesson: true,
    status: 1,
  };

  return (
    <MasterLayout
      title="Đề IELTS listening"
      columns={columns}
      useFindItemsMutation={useFindExaminationsMutation}
      disabledAll={true}
      additionalDefaultFilter={listeningFilter}
      sortObject={{ dataField: "name", order: "asc" }}
    />
  );
}
