import React from "react";

export default function Footer() {
  return (
    <section className="footer">
      <div className="container">
        <div className="content">
          <span>COPYRIGHT </span>
          <i className="fa-regular fa-copyright"></i>
          <span> 2023 BEVERLY.</span>
        </div>
      </div>
    </section>
  );
}
