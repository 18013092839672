import { QuestionType } from "../../../utils/constants";
import Input from "../Form/Input";

export default function JuniorQuestionList({
  section,
  questionsRef,
  result,
  setFieldValue,
  updateAnswer,
}) {
  return section.questions?.map((question, questionIndex) => (
    <div
      className="question-container"
      key={questionIndex}
      id={question.questionNo}
      ref={(el) => (questionsRef.current[question.questionNo - 1] = el)}
    >
      <div className="question-content">
        <strong className="question-number">{question.questionNo}</strong>
        <div
          className="question"
          dangerouslySetInnerHTML={{
            __html: question.content,
          }}
        />
      </div>
      {section.questionType === QuestionType.MutipleChoice.value && (
        <div className="option">
          {question.answerA && (
            <div className="form-check">
              <input
                type="radio"
                name={`examinationQuestionResults[${
                  question.questionNo - 1
                }].answer`}
                value="A"
                onChange={() =>
                  updateAnswer("A", result, question, setFieldValue)
                }
              />
              <label htmlFor="A">A. {question.answerA}</label>
            </div>
          )}
          {question.answerB && (
            <div className="form-check">
              <input
                type="radio"
                name={`examinationQuestionResults[${
                  question.questionNo - 1
                }].answer`}
                value="B"
                onChange={() =>
                  updateAnswer("B", result, question, setFieldValue)
                }
              />
              <label htmlFor="B">B. {question.answerB}</label>
            </div>
          )}
          {question.answerC && (
            <div className="form-check">
              <input
                type="radio"
                name={`examinationQuestionResults[${
                  question.questionNo - 1
                }].answer`}
                value="C"
                onChange={() =>
                  updateAnswer("C", result, question, setFieldValue)
                }
              />
              <label htmlFor="C">C. {question.answerC}</label>
            </div>
          )}
          {question.answerD && (
            <div className="form-check">
              <input
                type="radio"
                name={`examinationQuestionResults[${
                  question.questionNo - 1
                }].answer`}
                value="D"
                onChange={() =>
                  updateAnswer("D", result, question, setFieldValue)
                }
              />
              <label htmlFor="D">D. {question.answerD}</label>
            </div>
          )}
        </div>
      )}
      {section.questionType === QuestionType.Text.value && (
        <Input
          name={`examinationQuestionResults[${question.questionNo - 1}].answer`}
          onChange={(e) =>
            updateAnswer(e.currentTarget.value, result, question, setFieldValue)
          }
        />
      )}
    </div>
  ));
}
