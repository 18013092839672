import { useMutation, useQuery } from "react-query";
import baseApi from "./BaseApi";

const LESSON_URL = "/lessons";
const FIND_LESSON_URL = `${LESSON_URL}/find`;
const DELETE_LESSONS_URL = `${LESSON_URL}/deleteLessons`;

export function useFindLessonsMutation() {
  return useMutation(FIND_LESSON_URL, async (queryParams) => {
    return (await baseApi.post(FIND_LESSON_URL, queryParams)).data;
  });
}

export async function deleteLesson(id) {
  return (await baseApi.delete(`${LESSON_URL}/${id}`)).data;
}

export async function deleteLessons(ids) {
  return (await baseApi.post(DELETE_LESSONS_URL, ids)).data;
}

export function useGetLessonByIdQuery(id) {
  return useQuery(`${LESSON_URL}/${id}`, async () => {
    if (id) {
      return (await baseApi.get(`${LESSON_URL}/${id}`)).data;
    } else {
      return null;
    }
  });
}

export function useEditLessonMutation(isEdit) {
  return useMutation(LESSON_URL, async (lesson) => {
    if (isEdit) {
      return (await baseApi.put(LESSON_URL, lesson)).data;
    } else {
      return (await baseApi.post(LESSON_URL, lesson)).data;
    }
  });
}
