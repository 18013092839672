import { useMutation, useQuery } from "react-query";
import baseApi from "./BaseApi";

const EXAMINATION_URL = "/examinations";
const FIND_EXAMINATION_URL = `${EXAMINATION_URL}/find`;
const DELETE_EXAMINATIONS_URL = `${EXAMINATION_URL}/deleteExaminations`;
const DASHBOARD_EXAMINATION_URL = `${EXAMINATION_URL}/dashboard`;

export function useFindExaminationsMutation() {
  return useMutation(FIND_EXAMINATION_URL, async (queryParams) => {
    return (await baseApi.post(FIND_EXAMINATION_URL, queryParams)).data;
  });
}

export async function deleteExamination(id) {
  return (await baseApi.delete(`${EXAMINATION_URL}/${id}`)).data;
}

export async function deleteExaminations(ids) {
  return (await baseApi.post(DELETE_EXAMINATIONS_URL, ids)).data;
}

export function useGetExaminationByIdQuery(id, origin) {
  return useQuery(`${EXAMINATION_URL}/${id}`, async () => {
    if (id) {
      return (
        await baseApi.get(
          `${EXAMINATION_URL}/${id}${origin ? `?origin=${origin}` : ""}`
        )
      ).data;
    } else {
      return null;
    }
  });
}

export function useEditExaminationMutation() {
  return useMutation(EXAMINATION_URL, async (examination) => {
    return (await baseApi.post(EXAMINATION_URL, examination)).data;
  });
}

export function useGetDashboardExaminationsQuery() {
  return useQuery(DASHBOARD_EXAMINATION_URL, async () => {
    return (await baseApi.get(DASHBOARD_EXAMINATION_URL)).data;
  });
}