import React from "react";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../utils/Table/TableSortingUtils";
import MasterLayout from "../../components/MasterDetail/MasterLayout/MasterLayout";
import {
  useFindCoursesMutation,
  deleteCourse,
  deleteCourses,
} from "../../../apis/CourseApi";
import StatusColumn from "../../components/MasterDetail/ColumnFormatter/StatusColumn";

export default function AdminCoursesPage({ title, role }) {
  // Table columns
  const columns = [
    {
      dataField: "name",
      text: "Tên",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "numLessons",
      text: "Số bài học",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Trạng thái",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: StatusColumn,
    },
  ];

  return (
    <MasterLayout
      title={title}
      columns={columns}
      useFindItemsMutation={useFindCoursesMutation}
      deleteItem={deleteCourse}
      deleteItems={deleteCourses}
      readLink="/lessons"
      additionalDefaultFilter={{
        roles: role,
      }}
    />
  );
}
