import { Formik, Form } from "formik";
import * as Yup from "yup";
import _, { delay, isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Stepper, Step } from "react-form-stepper";

import LoadingIndicator from "../../../components/LoadingIndicator/LoadingIndicator";
import Input from "../../../components/Form/Input";
import {
  useEditExaminationMutation,
  useGetExaminationByIdQuery,
} from "../../../../apis/ExaminationApi";
import { MasterDetailCard } from "../../../components/MasterDetail/MasterDetailLayout/MasterDetailCard";
import { useBreadcrumbStore } from "../../../../stores/BreadcrumbStore";
import { v4 } from "uuid";
import { ExaminationType } from "../../../../utils/constants";
import { useState, useMemo } from "react";
import ExaminationCategoryEditForm from "../../../components/ExaminationPartialForm/ExaminationCategoryEditForm";
import { toast } from "react-toastify";

const QuestionSchema = Yup.object().shape({
  content: Yup.string().required("Vui lòng nhập nội dung câu hỏi"),
  answer: Yup.string().required("Vui lòng nhập đáp án"),
});

const QuestionGroupSchema = Yup.object().shape({
  numQuestions: Yup.number()
    .min(0, "Tối thiểu 1 câu hỏi")
    .max(50, "Tối đa 50 câu hỏi"),
  questionType: Yup.number(),
  questions: Yup.array().of(QuestionSchema),
});

const ExaminationCategorySchema = Yup.object().shape({
  numQuestionGroups: Yup.number()
    .min(1, "Tối thiểu 1 section")
    .max(3, "Tối đa 3 section"),
  questionGroups: Yup.array().of(QuestionGroupSchema),
});

const ValidateEditSchema = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập tên"),
  timeByMinutes: Yup.number()
    .required()
    .min(1, "Thời gian thi tối thiểu là 1 phút")
    .max(180, "Thời gian thi tối đa là 180 phút"),
  examinationCategories: Yup.array().min(1).of(ExaminationCategorySchema),
});

export default function ReadingTestEditPage() {
  const { id, lessonId } = useParams();
  const isEdit = id !== undefined;
  const {
    isLoading: isGetLoading,
    isError: isGetError,
    isSuccess: isGetSuccess,
    data,
  } = useGetExaminationByIdQuery(id);

  const initReading = {
    id: v4(),
    name: "",
    timeByMinutes: 0,
    examinationType: ExaminationType.Reading,
    numExaminationCategories: 0,
    examinationCategories: [],
    description: "",
    status: 0,
    lessonId,
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initValue = useMemo(() => ({ ...initReading, ...data?.data }), [data]);

  // set breadcrumbs
  const backUrl = "/admin/tests/reading";
  const { setBreadcrumbs } = useBreadcrumbStore();
  useEffect(() => {
    if (!lessonId) {
      const breadcrumbs = [
        { title: "Reading", pathname: "/admin/tests/reading" },
        { title: isEdit ? "Chỉnh sửa" : "Tạo mới", pathname: "" },
      ];
      setBreadcrumbs(breadcrumbs);
    }
  }, [isEdit, lessonId, setBreadcrumbs]);

  const [isFinish, setIsFinish] = useState(false);
  const { isLoading, isSuccess, mutate, isError, error } =
    useEditExaminationMutation();
  const navigate = useNavigate();
  const returnBack = () => {
    if (lessonId) {
      navigate(-1);
    } else {
      navigate(backUrl);
    }
  };
  const customId = "custom-id-yes";

  if (isSuccess && !isError) {
    console.debug("Submit success");
  }

  if (isSuccess && !isError && isFinish) {
    toast.success(
      isEdit ? "Cập nhật dữ liệu thành công!" : "Tạo mới thành công!",
      {
        toastId: customId,
      }
    );

    delay(() => {
      returnBack();
    }, 1000);
  } else if (isError && isFinish) {
    console.debug(error);
    toast.error(
      error?.response?.data?.message ??
        (isEdit
          ? "Cập nhật dữ liệu không thành công!"
          : "Tạo mới không thành công!"),
      {
        toastId: customId,
      }
    );
  }
  //#region steps
  const [totalSteps, setTotalSteps] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState([]);

  // Set complete when editing
  useEffect(() => {
    if (isEmpty(completed) && data?.data?.examinationCategories?.length > 0) {
      setCompleted(
        Array.from(
          { length: data.data.examinationCategories.length },
          () => true
        )
      );
    }

    if (
      data?.data?.numExaminationCategories &&
      totalSteps !== data.data.numExaminationCategories
    ) {
      setTotalSteps(data.data.numExaminationCategories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data?.numExaminationCategories]);

  const isFirstStep = () => {
    return activeStep === 0;
  };

  const isLastStep = () => {
    return activeStep === totalSteps - 1;
  };

  const handleNext = () => {
    setActiveStep((nextStep) => nextStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => {
    if (completed[step] && step !== activeStep) {
      setActiveStep(step);
    }
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const stepper = (
    <Stepper activeStep={activeStep}>
      {Array.from({ length: totalSteps }).map((_, index) => (
        <Step
          key={index}
          completed={completed[index] && activeStep !== index}
          onClick={() => handleStep(index)}
        ></Step>
      ))}
    </Stepper>
  );
  //#endregion

  const createInitExaminationCategory = (orderNo) => {
    return {
      id: v4(),
      mark: 0,
      orderNo,
      numQuestionGroups: 0,
      questionGroups: [],
    };
  };

  const handleNextAndSave = (
    errors,
    handleSubmit,
    examinationCategories,
    setFieldValue
  ) => {
    if (
      !errors.examinationCategories ||
      !errors.examinationCategories[activeStep]
    ) {
      handleSubmit();
      if (
        completed[activeStep] &&
        examinationCategories.length > activeStep + 1
      ) {
        handleNext();
      } else {
        examinationCategories.push(
          createInitExaminationCategory(activeStep + 1)
        );
        setFieldValue("examinationCategories", examinationCategories);
        handleComplete();
      }
    }
  };

  const handleChangeStep = (
    examinationCategories,
    newTotalSteps,
    setFieldValue
  ) => {
    const diff = newTotalSteps - totalSteps;
    if (newTotalSteps > 10 || newTotalSteps < 1 || diff === 0) {
      return;
    }

    if (diff < 0) {
      if (
        !window.confirm(
          "Thay đổi số part có thể dẫn đến mất dữ liệu bạn đã nhập. Bạn có chắc chắn muốn thay đổi ko?"
        )
      ) {
        return;
      }
    }

    if (diff > 0 && examinationCategories.length === 0) {
      examinationCategories.push(createInitExaminationCategory(0));
    } else if (diff < 0) {
      const deleteCount = -diff - (totalSteps - examinationCategories.length);
      if (deleteCount > 0 && examinationCategories.length > deleteCount) {
        examinationCategories.splice(
          examinationCategories.length - deleteCount,
          deleteCount
        );
      }
    }
    setTotalSteps(newTotalSteps);
    if (activeStep + 1 > newTotalSteps) {
      setActiveStep(0);
    }
    setFieldValue("numExaminationCategories", newTotalSteps);
    setFieldValue("examinationCategories", examinationCategories);
  };

  return (
    <MasterDetailCard
      title={isEdit ? "Chỉnh sửa bài test reading" : "Tạo mới bài test reading"}
    >
      {isGetLoading && <LoadingIndicator />}
      {isGetError && <div>Có lỗi xảy ra</div>}
      {isGetSuccess && (
        <>
          <div className="mb-4">
            Dữ liệu sẽ được lưu tự động khi bạn bấm tiếp theo. An tâm dữ liệu
            của bạn sẽ không mất nhé!
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={initValue}
            validateOnMount={true}
            validationSchema={ValidateEditSchema}
            onSubmit={_.debounce((values) => {
              if (!isLoading && !isGetLoading) {
                console.debug("Submitting");
                mutate(values);
              } else {
                console.debug("Submitting but loading");
              }
            }, 500)}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              errors,
              values,
              touched,
              isValid,
            }) => (
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-4">
                    <Input
                      label="Tên bài reading"
                      name="name"
                      placeholder="Tên bài reading"
                      required
                      error={errors.name}
                      touched={touched.name}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Input
                      label="Thời gian thi (1 - 180 phút)"
                      name="timeByMinutes"
                      placeholder="Thời gian thi"
                      type="number"
                      required
                      error={errors.timeByMinutes}
                      touched={touched.timeByMinutes}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Input
                      label="Số bài đọc"
                      name="numExaminationCategories"
                      placeholder="Số bài đọc"
                      type="number"
                      required
                      error={errors.numExaminationCategories}
                      touched={touched.numExaminationCategories}
                      onChange={(e) => {
                        handleChangeStep(
                          values.examinationCategories,
                          e.currentTarget.value,
                          setFieldValue
                        );
                      }}
                    />
                  </div>
                </div>

                {values.numExaminationCategories > 0 && (
                  <div>
                    {values.numExaminationCategories > 1 && stepper}
                    <div>
                      <ExaminationCategoryEditForm
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        prefix={`examinationCategories[${activeStep}]`}
                        examinationType={ExaminationType.Reading}
                        values={values.examinationCategories[activeStep]}
                        errors={
                          errors.examinationCategories &&
                          errors.examinationCategories[activeStep]
                        }
                        touched={
                          touched.examinationCategories &&
                          touched.examinationCategories[activeStep]
                        }
                      />
                    </div>
                  </div>
                )}

                <div className="form-action">
                  <Button
                    type="button"
                    onClick={returnBack}
                    className="btn btn-light btn-elevate"
                  >
                    Hủy
                  </Button>
                  {values.numExaminationCategories > 1 && (
                    <Button
                      disabled={isLoading || isFirstStep()}
                      onClick={handleBack}
                      className="btn btn-primary btn-elevate"
                    >
                      Trở lại
                    </Button>
                  )}
                  {values.numExaminationCategories > 1 && !isLastStep() && (
                    <Button
                      disabled={
                        errors.examinationCategories !== undefined &&
                        errors.examinationCategories[activeStep] !== undefined
                      }
                      onClick={() =>
                        handleNextAndSave(
                          errors,
                          handleSubmit,
                          values.examinationCategories,
                          setFieldValue
                        )
                      }
                      className="btn btn-primary btn-elevate"
                    >
                      Tiếp theo
                    </Button>
                  )}
                  <Button
                    disabled={isLoading || !isValid}
                    className="btn btn-primary btn-elevate"
                    onClick={() => {
                      handleSubmit();
                      setIsFinish(true);
                    }}
                  >
                    {isLoading && <LoadingIndicator />}
                    {!isLoading && "Lưu"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </MasterDetailCard>
  );
}
