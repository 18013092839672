import { Form, Formik } from "formik";
import _, { isEmpty } from "lodash";
import { useRef, useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import * as Yup from "yup";
import Input from "../Form/Input";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import RichEditor from "../RichEditor/RichEditor";
import { LessonType } from "../../../utils/constants";
import Select from "../Form/Select";
import AdminJuniorTestForm from "../AdminTestParts/AdminJuniorTestForm";

const ValidateEditSchema = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập tên"),
  description: Yup.string(),
});

export default function AdminJuniorLessonForm({
  initValue,
  isLoading,
  onSubmit,
  onCancel,
  isEdit
}) {
  const examRef = useRef(null);
  const [lessonType, setLessonType] = useState(
    initValue.lessonExaminations.length !== 0
      ? LessonType.Quiz.value
      : LessonType.Script.value
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initValue}
      validateOnMount={true}
      validationSchema={
        lessonType === LessonType.Script.value ? ValidateEditSchema : undefined
      }
      onSubmit={_.debounce((values) => {
        if (!isLoading) {
          if (lessonType === LessonType.Quiz.value && examRef.current) {
            examRef.current.handleSubmit();
            if (examRef.current.isValid) {
              if (
                isEmpty(values.lessonExaminations) &&
                examRef.current.values.id
              ) {
                values.lessonExaminations.push({
                  lessonId: values.id,
                  examinationId: examRef.current.values.id,
                });
              }

              values.name = examRef.current.values.name
              onSubmit(values);
            }
          } else if (lessonType === LessonType.Script.value) {
            onSubmit(values);
          }
        }
      }, 500)}
    >
      {({ setFieldValue, values, errors, touched, isValid }) => (
        <Form className="form form-label-right">
          <div className="form-group row">
            <div className="col-lg-2">
              <Select
                name="lessonType"
                label="Loại bài học"
                onChange={(e) => setLessonType(e.target.value)}
                value={lessonType}
                disabled={isEdit}
              >
                {Object.values(LessonType).map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </div>
          </div>
          {lessonType === LessonType.Script.value && (
            <>
              <div className="form-group row">
                <div className="col-lg-4">
                  <Input
                    label="Tên"
                    name="name"
                    placeholder="Tên bài học"
                    required
                    error={errors.name}
                    touched={touched.name}
                  />
                </div>
              </div>
              <div className="form-group">
                <Input
                  label="Mô tả"
                  name="description"
                  as="textarea"
                  placeholder="Mô tả"
                  error={errors.description}
                  touched={touched.description}
                />
              </div>

              <Tabs>
                <Tab eventKey="script" title="Script">
                  <div className="form-group">
                    <RichEditor
                      fieldName="content"
                      setFieldValue={setFieldValue}
                      value={values.content}
                      error={errors.content}
                      touched={touched.content}
                    />
                  </div>
                </Tab>
                <Tab eventKey="vocab" title="Vocab">
                  <div className="form-group">
                    <RichEditor
                      fieldName="vocabulary"
                      setFieldValue={setFieldValue}
                      value={values.vocabulary}
                      error={errors.vocabulary}
                      touched={touched.vocabulary}
                    />
                  </div>
                </Tab>
              </Tabs>
            </>
          )}

          {lessonType === LessonType.Quiz.value && (
            <div className="mt-4 mb-4">
              <AdminJuniorTestForm
                formRef={examRef}
                id={values.lessonExaminations[0]?.examinationId}
                lessonId={values.id}
              />
            </div>
          )}

          <div className="form-action">
            <Button
              type="button"
              onClick={onCancel}
              className="btn btn-light btn-elevate"
            >
              Hủy
            </Button>
            <Button
              disabled={
                isLoading ||
                (lessonType === LessonType.Script.value && !isValid)
              }
              className="btn btn-primary btn-elevate"
              type="submit"
            >
              {isLoading && <LoadingIndicator />}
              {!isLoading && "Lưu"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
