import React from "react";
import { Card, CardBody, CardHeader } from "../../Card/Card";

export function MasterDetailCard({ children, title }) {
  return (
    <Card>
      <CardHeader title={title}></CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
}
