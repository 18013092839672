import { Form, Formik } from "formik";
import _, { delay } from "lodash";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useEditUserMutation,
  useGetUserByIdQuery,
} from "../../../apis/UserApi";
import { useBreadcrumbStore } from "../../../stores/BreadcrumbStore";
import Input from "../../components/Form/Input";
import Select from "../../components/Form/Select";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { MasterDetailCard } from "../../components/MasterDetail/MasterDetailLayout/MasterDetailCard";
import { RoleList } from "../../../utils/constants";

// Validation schema
const ValidateEditSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(50, "Tên không được quá 50 kí tự")
    .required("Vui lòng nhập tên"),
  lastName: Yup.string()
    .max(50, "Họ không được quá 50 kí tự")
    .required("Vui lòng nhập họ và tên đệm"),
  password: Yup.string()
    .min(6, "Mật khẩu tối thiểu 6 ký tự")
    .max(20, "Mật khẩu tối đa 20 ký tự")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[><?@+'`~^%&*[\]{}.!#|\\"$:;=/(),-])[A-Za-z\d><?@+'`~^%&*[\]{}.!#|\\"$:;=/(),-]{6,}$/,
      "Mật khẩu phải bao gồm chữ in hoa, in thường, số và kí tự"
    ),
  repassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "Mật khẩu xác nhận không khớp"
  ),
});

const ValidateCreateSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(50, "Tên không được quá 50 kí tự")
    .required("Vui lòng nhập tên"),
  lastName: Yup.string()
    .max(50, "Họ không được quá 50 kí tự")
    .required("Vui lòng nhập họ và tên đệm"),
  email: Yup.string()
    .max(50, "Mail không được quá 50 kí tự")
    .email("Sai cấu trúc email")
    .required("Vui lòng nhập email"),
  role: Yup.string().required("Vui lòng nhập quyền của tài khoản"),
  password: Yup.string()
    .min(6, "Mật khẩu tối thiểu 6 ký tự")
    .max(20, "Mật khẩu tối đa 20 ký tự")
    .required("Vui lòng nhập mật khẩu")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[><?@+'`~^%&*[\]{}.!#|\\"$:;=/(),-])[A-Za-z\d><?@+'`~^%&*[\]{}.!#|\\"$:;=/(),-]{6,}$/,
      "Mật khẩu phải bao gồm chữ in hoa, in thường, số và kí tự"
    ),
  repassword: Yup.string()
    .required("Vui lòng nhập xác nhận mật khẩu")
    .oneOf([Yup.ref("password")], "Mật khẩu xác nhận không khớp"),
});

const InitUser = {
  firstName: "",
  lastName: "",
  role: "USER",
  email: "",
  password: "",
  repassword: "",
};

export default function AdminUserEditPage() {
  const { id } = useParams();
  const isEdit = id !== undefined;
  const {
    isLoading: isGetLoading,
    isError: isGetError,
    isSuccess: isGetSuccess,
    data,
  } = useGetUserByIdQuery(id);

  // set breadcrumbs
  const { setBreadcrumbs } = useBreadcrumbStore();
  useEffect(() => {
    const breadcrumbs = [
      { title: "Tài khoản", pathname: "/admin/users" },
      { title: isEdit ? "Chỉnh sửa" : "Tạo mới", pathname: "" },
    ];
    setBreadcrumbs(breadcrumbs);
  }, [isEdit, setBreadcrumbs]);

  const { isLoading, isSuccess, isError, mutate, error } =
    useEditUserMutation();
  const navigate = useNavigate();
  const returnBack = () => {
    navigate("/admin/users");
  };

  const customId = "custom-id-yes";

  if (isSuccess) {
    toast.success(
      isEdit ? "Cập nhật dữ liệu thành công!" : "Tạo mới thành công!",
      {
        toastId: customId,
      }
    );
    delay(() => {
      returnBack();
    }, 1000);
  } else if (isError) {
    toast.error(
      error?.response?.data?.message ??
        (isEdit
          ? "Cập nhật dữ liệu không thành công!"
          : "Tạo mới không thành công!"),
      {
        toastId: customId,
      }
    );
  }

  return (
    <MasterDetailCard
      title={isEdit ? "Chỉnh sửa tài khoản" : "Tạo mới tài khoản"}
    >
      {isGetLoading && <LoadingIndicator />}
      {isGetError && <div>Có lỗi xảy ra</div>}
      {isGetSuccess && (
        <Formik
          enableReinitialize={true}
          initialValues={{ ...InitUser, ...data?.data }}
          validateOnMount={true}
          validationSchema={isEdit ? ValidateEditSchema : ValidateCreateSchema}
          onSubmit={_.debounce((values) => {
            mutate(values);
          }, 500)}
        >
          {({
            setFieldValue,
            handleBlur,
            values,
            touched,
            errors,
            isValid,
            dirty,
          }) => (
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-4">
                  <Input
                    label="Họ và tên đệm"
                    name="lastName"
                    placeholder="Họ và tên đệm"
                    required
                    error={errors.lastName}
                    touched={touched.lastName}
                  />
                </div>
                <div className="col-lg-4">
                  <Input
                    label="Tên"
                    name="firstName"
                    placeholder="Tên"
                    required
                    error={errors.firstName}
                    touched={touched.firstName}
                  />
                </div>
                <div className="col-lg-4">
                  <Select
                    name="role"
                    label="Quyền"
                    required
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("role", value);
                    }}
                    onBlur={handleBlur}
                    value={values.role}
                  >
                    {RoleList.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-4">
                  <Input
                    label="Email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    required
                    error={errors.email}
                    touched={touched.email}
                  />
                </div>
                <div className="col-lg-4">
                  <Input
                    label="Mật khẩu"
                    name="password"
                    type="password"
                    required={!isEdit}
                    placeholder="Mật khẩu"
                    error={errors.password}
                    touched={touched.password}
                    autoComplete="new-password"
                  />
                </div>
                <div className="col-lg-4">
                  <Input
                    label="Xác nhận mật khẩu"
                    name="repassword"
                    type="password"
                    required={!isEdit}
                    placeholder="Xác nhận mật khẩu"
                    error={errors.repassword}
                    touched={touched.repassword}
                    autoComplete="new-password"
                  />
                </div>
              </div>

              <div className="form-action">
                <Button
                  type="button"
                  onClick={returnBack}
                  className="btn btn-light btn-elevate"
                >
                  Hủy
                </Button>
                <Button
                  disabled={isLoading || !isValid || !dirty}
                  type="submit"
                  className="btn btn-primary btn-elevate"
                >
                  {isLoading && <LoadingIndicator />}
                  {!isLoading && "Lưu"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </MasterDetailCard>
  );
}
