import React, { useEffect, useMemo, useState } from "react";
import { FaNewspaper, FaRegClock } from "react-icons/fa";
import { RiNewspaperFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { useGetExaminationByIdQuery } from "../../../../apis/ExaminationApi";
import LoadingIndicator from "../../../components/LoadingIndicator/LoadingIndicator";
import CountdownTimer from "../../../components/CountdownTimer/CountdownTimer";
import TestResult from "../../../components/StudentTestParts/TestResult";
import { useCreateExaminationResultMutation } from "../../../../apis/ExaminationResultApi";
import { toast } from "react-toastify";
import ListeningParts from "../../../components/IeltsParts/ListeningParts";

export default function ListeningInfoPage() {
  const { id } = useParams();
  const { isError, isLoading, data } = useGetExaminationByIdQuery(id);
  const exam = useMemo(() => data?.data, [data]);
  const [isStartedTest, setIsStartedTest] = useState(false);
  const [isFinishTest, setIsFinishTest] = useState(false);

  const {
    isLoading: isLoadingSubmitResult,
    isSuccess: isSuccessSubmitResult,
    data: submitResultData,
    mutate: submitResult,
    isError: isErrorSubmitResult,
  } = useCreateExaminationResultMutation();

  useEffect(() => {
    if (!isLoadingSubmitResult && isSuccessSubmitResult) {
      setIsFinishTest(true);
    }
  }, [isLoadingSubmitResult, isSuccessSubmitResult, setIsFinishTest]);

  if (isErrorSubmitResult) {
    toast.error("Nộp bài không thành công!");
  }

  return (
    <div className="listening-test-page card card-custom gutter-b">
      {(isLoading || isLoadingSubmitResult) && <LoadingIndicator />}
      {isError && <div>Có lỗi xảy ra, vui lòng thử lại sau.</div>}
      {!(isLoading || isLoadingSubmitResult) && !isError && exam && (
        <>
          <div className="card-header align-items-center">
            <h3 className="card-title font-weight-bolder text-dark">
              {exam.name}
            </h3>
            {isStartedTest && !isFinishTest && (
              <CountdownTimer
                seconds={exam.timeByMinutes * 60}
                onFinish={() => setIsFinishTest(true)}
              />
            )}
          </div>
          <div className="card-body">
            {!isStartedTest && (
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="test-info"
                  role="tabpanel"
                  aria-labelledby="test-info-tab"
                >
                  <p>{exam.description}</p>
                  <div className="general-info mb-4">
                    <p>
                      <FaRegClock />
                      <span>Thời gian làm bài: {exam.timeByMinutes} phút</span>
                    </p>
                    <p>
                      <RiNewspaperFill />
                      <span>{exam.numExaminationCategories} track(s)</span>
                    </p>
                    <p>
                      <FaNewspaper />
                      <span>{exam.numQuestions} câu hỏi</span>
                    </p>
                  </div>
                  <button
                    className="btn btn-secondary"
                    onClick={setIsStartedTest}
                  >
                    Làm bài test
                  </button>
                </div>
              </div>
            )}
            {isStartedTest && !submitResultData && (
              <ListeningParts
                exam={exam}
                submitResult={submitResult}
                isFinish={isFinishTest}
              />
            )}
            {isFinishTest && submitResultData && (
              <TestResult
                result={submitResultData.data}
                exam={exam}
                testUrl="/ielts/listening"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
