import React from "react";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../../utils/Table/TableSortingUtils";
import MasterLayout from "../../../components/MasterDetail/MasterLayout/MasterLayout";
import {
  useFindExaminationsMutation,
  deleteExamination,
  deleteExaminations,
} from "../../../../apis/ExaminationApi";
import { ExaminationType } from "../../../../utils/constants";

export default function WritingTestsPage() {
  // Table columns
  const columns = [
    {
      dataField: "name",
      text: "Tên",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "numQuestions",
      text: "Số task",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ];

  const writingFilter = {
    examinationType: ExaminationType.Writing,
  };

  return (
    <MasterLayout
      title="Bài test writing"
      columns={columns}
      useFindItemsMutation={useFindExaminationsMutation}
      deleteItem={deleteExamination}
      deleteItems={deleteExaminations}
      disabledRead={true}
      additionalDefaultFilter={writingFilter}
    />
  );
}
