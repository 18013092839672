import React from "react";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../../utils/Table/TableSortingUtils";
import MasterLayout from "../../../components/MasterDetail/MasterLayout/MasterLayout";
import {
  useFindLessonsMutation,
  deleteLesson,
  deleteLessons,
} from "../../../../apis/LessonApi";
import { useParams } from "react-router-dom";
import { useCourseHook } from "../../../../hooks/CourseHooks";

export default function LessonsPage() {
  // Table columns
  const columns = [
    {
      dataField: "name",
      text: "Tên",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "description",
      text: "Mô tả",
    },
  ];

  const { courseId } = useParams();
  const courseIdFilter = {
    courseId,
  };

  const { courseTitle, courseUrl } = useCourseHook();
  const additionalBreadcrumbs = [{ title: courseTitle, pathname: courseUrl }];

  return (
    <MasterLayout
      title="Bài học"
      columns={columns}
      useFindItemsMutation={useFindLessonsMutation}
      deleteItem={deleteLesson}
      deleteItems={deleteLessons}
      disabledRead={true}
      additionalBreadcrumbs={additionalBreadcrumbs}
      additionalDefaultFilter={courseIdFilter}
    />
  );
}
