import { UserRoles } from "../../../utils/constants";
import AdminCoursesPage from "./AdminCoursesPage";

export default function AdminJuniorCoursesPage() {
  return (
    <AdminCoursesPage
      title="Khóa học ôn thi vào lớp 10"
      role={UserRoles.Junior}
    />
  );
}
