import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
// import * as IoIcons from 'react-icons/io';
import * as RiIcons from "react-icons/ri";
import { RoutingPath } from "../../../../utils/RouteUtils";
// import * as BsIcons from "react-icons/bs";

export const IeltsSidebarData = [
  {
    title: "Trang chủ",
    path: "/home",
    icon: <AiIcons.AiFillHome />,
    iconOpen: <RiIcons.RiArrowDownSLine />,
    iconClose: <RiIcons.RiArrowRightSLine />,
  },
  {
    title: "Thư viện bài test",
    icon: <RiIcons.RiNewspaperFill />,
    iconOpen: <RiIcons.RiArrowDownSLine />,
    iconClose: <RiIcons.RiArrowRightSLine />,
    subNav: [
      {
        title: "IELTS Reading",
        path: "/ielts/reading",
      },
    ],
  },
  {
    title: "Khóa học",
    path: "/courses-ielts",
    icon: <RiIcons.RiNewspaperFill />,
    iconOpen: <RiIcons.RiArrowDownSLine />,
    iconClose: <RiIcons.RiArrowRightSLine />,
  },
];

export const JuniorSidebarData = [
  {
    title: "Trang chủ",
    path: "/home",
    icon: <AiIcons.AiFillHome />,
    iconOpen: <RiIcons.RiArrowDownSLine />,
    iconClose: <RiIcons.RiArrowRightSLine />,
  },
  {
    title: "Ôn thi vào lớp 10",
    path: "/junior",
    icon: <RiIcons.RiNewspaperFill />,
    iconOpen: <RiIcons.RiArrowDownSLine />,
    iconClose: <RiIcons.RiArrowRightSLine />,
  },
  {
    title: "Khóa học",
    path: "/courses-junior",
    icon: <RiIcons.RiNewspaperFill />,
    iconOpen: <RiIcons.RiArrowDownSLine />,
    iconClose: <RiIcons.RiArrowRightSLine />,
  },
];

export const Junior6SidebarData = [
  {
    title: "Trang chủ",
    path: "/home",
    icon: <AiIcons.AiFillHome />,
    iconOpen: <RiIcons.RiArrowDownSLine />,
    iconClose: <RiIcons.RiArrowRightSLine />,
  },
  {
    title: "Bài test lớp 6",
    path: "/junior6",
    icon: <RiIcons.RiNewspaperFill />,
    iconOpen: <RiIcons.RiArrowDownSLine />,
    iconClose: <RiIcons.RiArrowRightSLine />,
  },
];

export const Junior7SidebarData = [
  {
    title: "Trang chủ",
    path: "/home",
    icon: <AiIcons.AiFillHome />,
    iconOpen: <RiIcons.RiArrowDownSLine />,
    iconClose: <RiIcons.RiArrowRightSLine />,
  },
  {
    title: "Bài test lớp 7",
    path: "/junior7",
    icon: <RiIcons.RiNewspaperFill />,
    iconOpen: <RiIcons.RiArrowDownSLine />,
    iconClose: <RiIcons.RiArrowRightSLine />,
  },
];

export const AdminSidebarData = [
  {
    title: "Tài khoản",
    path: "/admin/users",
    icon: <FaIcons.FaUser />,
    iconOpen: <RiIcons.RiArrowDownSLine />,
    iconClose: <RiIcons.RiArrowRightSLine />,
  },
  {
    title: "Bài test",
    icon: <RiIcons.RiNewspaperFill />,
    iconOpen: <RiIcons.RiArrowDownSLine />,
    iconClose: <RiIcons.RiArrowRightSLine />,
    subNav: [
      {
        title: "Bài test lớp 6",
        path: "/admin/tests/junior6",
      },
      {
        title: "Bài test lớp 7",
        path: "/admin/tests/junior7",
      },
      {
        title: "Ôn thì vào lớp 10",
        path: "/admin/tests/junior",
      },
      {
        title: "Đề thi THPT",
        path: "/admin/tests/highschool",
      },
      {
        title: "IELTS reading",
        path: "/admin/tests/reading",
      },
      {
        title: "IELTS listening",
        path: "/admin/tests/listening",
      },
    ],
  },
  {
    title: "Khóa học",
    icon: <RiIcons.RiNewspaperFill />,
    iconOpen: <RiIcons.RiArrowDownSLine />,
    iconClose: <RiIcons.RiArrowRightSLine />,
    subNav: [
      {
        title: "IELTS",
        path: `/admin/${RoutingPath.Admin.IeltsCourse}`,
      },
      {
        title: "Ôn thì vào lớp 10",
        path: `/admin/${RoutingPath.Admin.JuniorCourse}`,
      },
    ],
  },
  {
    title: "Kết quả test",
    path: "/admin/results",
    icon: <RiIcons.RiNewspaperFill />,
    iconOpen: <RiIcons.RiArrowDownSLine />,
    iconClose: <RiIcons.RiArrowRightSLine />,
  },
];
